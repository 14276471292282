import React, { useContext, useEffect, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { checkIfEmpty } from '../../utility';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import AlertContext from '../../context/alerts';
import Spinner from '../../components/spinner';
import spinner from '../../assets/images/spinner.gif';
import FileUploadIcon from '@mui/icons-material/FileUpload';


function EditBillModal({ onClose, open, billData }) {

    const { sendAlert } = useContext(AlertContext)

    const inputFileRef = useRef(null);

    const [modalData, setModalData] = useState(billData)
    const [filesData, setFilesData] = useState([]);

    let purchase_id = {
        purchase_id: billData?.purchase_id,
    }

    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer("UploadFileList ", purchase_id)

    const { setDataToServer, error } = usePostDataToServer("/UploadFileDelete", "UploadFileList", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            // onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const uploadFileAdd = usePostDataToServer("/uploadFileAdd ", "UploadFileList", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const deleteOutstandingifileFront = (deletedId) => {
        let filterData = handleGetData?.data?.data?.filter(ft => ft?.media_id !== deletedId)
        let val = modalData
        val.media = filterData
        setModalData(val);
        let deleteId = {
            media_id: deletedId
        }
        setDataToServer.mutate(deleteId);
    }

    const handleUploadFile = () => {
        inputFileRef.current.click();
    }
    const handleSetFile = (e) => {
        let files = e.target.files
        files = [...files]
        let newdata = filesData.slice()
        let newarr = newdata?.concat(files)
        setFilesData(newarr)
    }
    // console.log("state", filesData);

    const handleRemoveFile = (id) => {
        let newarr = filesData.slice()
        newarr.splice(id, 1)
        setFilesData(newarr)
    }
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }

    const editFiles = (e) => {
        e.preventDefault()
        let data = {
            "bill[]": filesData,
            purchase_id: billData?.purchase_id
        }
        uploadFileAdd?.setDataToServer?.mutate(data)
    }
    return (<Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className='bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>View/Edit Purchase Bill</h1>
                </div>
                <div>
                    {setDataToServer.isLoading ? <Spinner /> : ""}
                </div>
                <div onClick={onClose}>
                    <IconButton>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </div>
        <form onSubmit={(e) => editFiles(e)} className="py-7">
            <div className='flex justify-center mb-10'>
                <button
                    type="button"
                    className=" btn3 text-white"
                    onClick={handleUploadFile}><FileUploadIcon />Upload</button>
                <input
                    ref={inputFileRef}
                    accept=".pdf,.xlsx"
                    style={{ display: "none" }}
                    multiple
                    type="file"
                    onChange={handleSetFile}
                />
            </div>
            <div className='ml-5 space-y-9'>
                {handleGetData.isLoading ? <div className='w-10 h-10 mx-[16rem]'>
                    <img src={spinner} alt="spinner" className='w-full h-full' />
                </div> : handleGetData?.data?.data <= 0 ? <p className='mx-[13.5rem]'>No Bills Available</p> : handleGetData?.data?.data?.map((file, idx) => {
                    const { media_name, media_id, media_url } = file;
                    return <div key={idx} className=" flex mt-5 mb-4">
                        <a href={media_url} target="blank">{media_name}</a>
                        <div className="ml-12">
                            <button onClick={() => deleteOutstandingifileFront(media_id)}
                                className="ml-px" type='button'><CloseIcon className="" /></button>
                        </div>
                    </div>
                })}
                {filesData?.map((file, idx) => {
                    const { name } = file;
                    return <div key={idx} className="flex mt-5 mb-4 justify-between">
                        <p className=''>{name}</p>
                        <div className="">
                            <button onClick={() => handleRemoveFile(idx)}
                                className="mr-1"
                                type='button'><CloseIcon className="" /></button>
                        </div>
                    </div>
                })}
            </div>
            {uploadFileAdd?.setDataToServer.isLoading ?
                <Spinner /> : <div className="flex justify-end mt-12 mr-3">
                    <button type='submit' className='btn3 text-white'>Edit</button>
                </div>}
        </form>
    </Dialog>);
}
export default EditBillModal;