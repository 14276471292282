import React, { useState } from 'react'
import ProductLayout from '.'
import Spinner from '../../components/spinner'
import Errors from '../errors'
import AddAssembledProduct from './AddAssembledProduct'
import ProductModal from './ProductModal'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditAssembledProduct from './EditAssembledProduct'
import ReactPaginate from 'react-paginate'
import useGetDataFromServer from '../../hooks/useGetDataFromServer'
import Pagination from '../../components/pagination'

function AssembledProduct() {
  const initialData = {
    page_no: 0,
    limit: 20,
    search_query: '',
  }
  const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
  const handleOpenCreateProduct = () => {
    setIsCreateDialogOpen(true)
  }
  const handleCloseDialog = () => {
    setIsCreateDialogOpen(false)
  }
  const [openProductModal, setProductModal] = useState(false)
  const handleProductModal = () => {
    setProductModal(true)
  }
  const [openEditModal, setEditModal] = useState(false)
  const handleProductEdit = () => {
    setEditModal(true)
  }
  const { handleGetData,paramsObject } = useGetDataFromServer('getAssembleProductList', initialData)
  const handlePageClick = () => { }
  return (
    <>
      <ProductLayout>
        <div className="py-4 border-t border-gray-300">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <input type="text" className="input2" name="email" placeholder="Search Product..."></input>
            </div>
            <button className="btn3" onClick={handleOpenCreateProduct}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              <span>Add Product</span>
            </button>
          </div>
        </div>
        {handleGetData.isLoading ? <Spinner />
          : handleGetData.isError ? <Errors errorObject={handleGetData?.error} inline/>
            : <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
              <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead>
                  <tr className="text-left">
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Sr.no
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Product ID
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Product Name
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Status
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      View Product 
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {handleGetData.data.data.map((list,listIdx) => {
                    const {asm_prod_name,id} = list
                    return <tr key={listIdx}>
                    <td className="border-dashed border-t border-gray-200 userId">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{listIdx + 1}</span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{id}</span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{asm_prod_name}</span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <select className="text-sm text-gray-700">
                        <option value="">--Select Status--</option>
                        <option value="ACTIVE">Active</option>
                        <option value="SUSPENDED">Suspended</option>
                        <option value="ADMIN_DISABLED">Admin disabled</option>
                        <option value="DISABLED">Disabled</option>
                      </select>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm ml-8 cursor-pointer">
                        <RemoveRedEyeIcon onClick={handleProductModal} />
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                        <span data-id='1' onClick={handleProductEdit}><EditIcon /></span>
                        <DeleteIcon onClick={handleProductModal} />
                      </span>
                    </td>
                  </tr>
                  })}
                </tbody>
              </table>
            </div>
            <div className=" my-7">
            <Pagination currentPage={paramsObject.page_no} pageCount={handleGetData?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick}/>
          </div>
          </>
            }
            
      </ProductLayout>
      <AddAssembledProduct open={isCreatDialogOpen} onClose={handleCloseDialog} />
      <EditAssembledProduct open={openEditModal} onClose={() => setEditModal(false)} />
      <ProductModal open={openProductModal} handleClose={() => setProductModal(false)} />
    </>
  )
}

export default AssembledProduct