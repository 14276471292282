
import React, { useState, useRef, createContext } from 'react'
import { checkIfNotEmpty } from '../utility';

const AlertContext = createContext();

export const AlertContextProvider = ({ children }) => {
  const [alerts, setAlerts] = useState({
    arr: [],
  });

  const alertTypes = ["ERROR", "WARNING", "SUCCESS", "MESSAGE"];

  const counter = useRef(0);

  const dismissAlert = (index) => {
    let copy = {...alerts}
    copy.arr[index].isInvisible = true;
    setAlerts(copy)

    setTimeout(() => {
      let copy = {...alerts}
      copy.arr[index].isHidden = true;
      setAlerts(copy)
    }, 500);
  };

  const sendAlert = (message, type, autohide, autohideTime) => {
    let finalType;
    let finalAutohideTime = checkIfNotEmpty(autohideTime) ? autohideTime : 5000;
    let finalAutohideValue = checkIfNotEmpty(autohide) ? autohide : true;

    let newIndex = counter.current;
    counter.current = newIndex + 1;
    if (alertTypes.includes(type?.toUpperCase())) finalType = type;
    else finalType = "MESSAGE";
    let copy = {...alerts}
    copy?.arr?.push({
      id: newIndex,
      message: message,
      type: finalType,
      isInvisible: true,
      isHidden: false,
    });
    setAlerts(copy)

    setTimeout(() => {
      let copy = {...alerts}
            copy.arr[newIndex].isInvisible = false;
      setAlerts(copy)
    }, 100);

    if (finalAutohideValue) {
      
      setTimeout(() => {
        let copy = {...alerts}
        copy.arr[newIndex].isInvisible = true;
        setAlerts(copy)
      }, finalAutohideTime - 500);
      setTimeout(() => {
        let copy = {...alerts}
        copy.arr[newIndex].isHidden = true;
        setAlerts(copy)
      }, finalAutohideTime);
    }
  };

  return (
    <AlertContext.Provider value={{ alerts, dismissAlert, sendAlert }}>
      {children}
    </AlertContext.Provider >
  )
};

export default AlertContext;
