import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import AddRooms from '../AddRooms/AddRooms';
import { checkIfEmpty, checkIfNotEmpty } from '../../utility';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import AlertContext from '../../context/alerts';
import Spinner from '../spinner';
import spinner from '../../assets/images/spinner.gif'



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddInfrastructure({ open, onClose, isUpdate, buildingId }) {
    const { sendAlert } = useContext(AlertContext)
    let updateId = {
        buildingId: buildingId
    }
    const initialValue = [{
        buildingName: "",
        wing: []
    }]
    const [infrastructureData, setInfrastructureData] = useState(initialValue);

    const { setDataToServer } = usePostDataToServer("/createInfrastructure", "getBuildingList", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err.message)
        }
    })


    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [currentFloorId, setCurrentFloorId] = useState("")
    const [currentWingId, setCurrentWingId] = useState("")


    const handleOpenAddRooms = (floorId, wingId) => {
        setCurrentFloorId(floorId)
        setCurrentWingId(wingId)
        setIsCreateDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false)
    }

    const handleClearAndCloseForm = () => {
        onClose()
    }

    const handleAddWingForm = (e) => {
        let obj = {
            wingName: "",
            floor: [],
            levelCount: ''
        }
        const newobj = infrastructureData.slice()
        newobj[0].wing.push(obj)
        setInfrastructureData(newobj);
    }
    const handleInputs = (e) => {
        let value = e.target.value
        let newobj = infrastructureData.slice()
        newobj[0].buildingName = value
        setInfrastructureData(newobj)
    }
    const handleWingDelete = (wingId) => {
        const newobj = infrastructureData.slice()
        newobj[0].wing.splice(wingId, 1)
        setInfrastructureData(newobj);
    }
    const handleFloorDelete = (wingId, floorId) => {
        let wingData = infrastructureData.slice()
        wingData[0].wing[wingId].floor.splice(floorId, 1)
        setInfrastructureData(wingData);
    }
    // const popToast = (message) => {
    //     toast(message)
    // };
    const handleWingName = (e, wingId) => {
        let value = e.target.value;
        let newObj = infrastructureData.slice()
        // if (checkIfEmpty(value)) {
        //     popToast("Floor input should not be empty ")
        //     return
        // }
        newObj[0].wing[wingId].wingName = value;
        setInfrastructureData(newObj);
    }
    const handleFloorName = (e, wingId, floorId) => {
        let value = e.target.value;
        let newObj = infrastructureData.slice();
        // let wingData = infrastructureData[wingId]
        // console.log('wingData.floor[floorId]', wingData.floor[floorId])
        newObj[0].wing[wingId].floor[floorId].floorName = value;
        setInfrastructureData(newObj);
    }
    const handleFloorInput = (e, id) => {
        let value = e.target.value;
        let newObj = infrastructureData.slice();
        newObj[0].wing[id].levelCount = value;
        setInfrastructureData(newObj);
    }

    const generateFloorField = (e, id) => {
        e.preventDefault()
        let floorarr = [];
        let data = infrastructureData.slice()
        if (checkIfEmpty(data[0]?.wing[id]?.levelCount)) {
            sendAlert("Floor input is empty", "WARNING")
            return;
        }
        let offset = data[0].wing[id].floor.length;
        for (let i = 0; i < data[0].wing[id].levelCount; i++) {
            let obj = {
                floorName: 'Floor ' + (offset + i + 1), rooms: []
            }
            floorarr.push(obj)
        }
        let dataCopy = data
        data[0].wing[id].floor = data[0].wing[id].floor.concat(floorarr);
        setInfrastructureData(data)
    }

    const addRooms = (floorId, roomName, roomType, wingId) => {
        let roomObj = {
            roomName: roomName,
            roomType: roomType
        }
        let rooms = []
        rooms.push(roomObj)
        let roomData = infrastructureData[0].wing[wingId].floor[floorId].rooms;
        roomData.push(roomObj)
    }

    const handleRoomDelete = (wingId, floorId, roomId) => {
        let data = infrastructureData.slice()
        data[0].wing[wingId].floor[floorId].rooms.splice(roomId, 1)
        setInfrastructureData(data);
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        let message = "Building data should not be empty"
        if (infrastructureData[0].wing.length <= 0) {
            sendAlert(message, "WARNING")
        } else {
            let data = {
                infrastructure: JSON.stringify(infrastructureData)
            }
            setDataToServer.mutate(data)
        }
    }
    // useEffect(() => {
    //     if (isUpdate) {
    //         getSingleData?.setDataToServer?.mutate(updateId)
    //         //setInfrastructureData(getSingleData?.data?.data)
    //     }
    // }, [isUpdate])

    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={handleClearAndCloseForm} TransitionComponent={Transition}>
            <div className='container_xxl'>
                <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={handleClearAndCloseForm}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading capitalize text-2xl'>Add Infrastructure</h1>
                    </div>
                    {/* <div>
                        {setDataToServer.isLoading ? <div className="flex justify-end"> <Spinner /> </div> : ""}
                    </div> */}
                </div>
                <form onSubmit={handleSubmitForm}>
                    <div className="flex justify-end">
                        {setDataToServer.isLoading ? <img src={spinner} alt="spinner" className='w-14 h-14' /> : infrastructureData[0].wing.length <= 0 ? <button
                            type="submit" disabled
                            className="bg-blue-300 rounded font-normal text-base text-white py-2 px-4 lg:p-2 lg:m-5">
                            <span className="px-3">Save</span>
                        </button> : <button
                            type="submit"
                            className="bg-[#0084b5] rounded font-normal text-base text-white py-2 px-4 lg:p-2 lg:m-5">
                            <span className="px-3">Save</span>
                        </button>}
                    </div>
                    <label className="text-gray-700 text-base font-bold mt-3.5 text-center">Building Name</label>
                    <div className='flex justify-center mt-1'>
                        <input required className='w-full h-12 border-2 border-grey px-2 rounded-md'
                            name='buildingName'
                            placeholder='Building Name'
                            onChange={(e) => handleInputs(e)}></input>
                    </div>
                    <div className="mt-5">
                        <div className="space-y-4 w-11/12 mx-auto">
                            {checkIfNotEmpty(infrastructureData[0]) && infrastructureData[0]?.wing?.map((data, windId) => {
                                return <div className="flex items-center justify-center" key={windId}>
                                    <div className="w-full border border-grey-200 p-4">
                                        <div className="flex space-x-14 flex-1 justify-center">
                                            <label className="text-gray-700 text-base font-bold mt-3.5 text-center">Wing Name</label>
                                            <input required className="input w-[48%] h-12" type="text" placeholder="Wing Name" value={data.wingName} onChange={(e) => handleWingName(e, windId)} />
                                            <form className="space-x-5">
                                                <input required className="input w-24 h-12" type="tel" placeholder="e.g 9" onChange={(e) => handleFloorInput(e, windId)} value={data.levelCount} />
                                                <button onClick={(e) => generateFloorField(e, windId)}
                                                    type="button"
                                                    className="bg-[#0084b5] text-white  font-normal rounded mb-4 text-5xl lg:text-base lg:w-20 lg:mt-2 lg:rounded-full text-center py-1">
                                                    <AddCircleIcon />
                                                </button>
                                            </form>
                                            <div className="removeWing-wrapper cursor-pointer mt-2.5" onClick={() => handleWingDelete(windId)}>
                                                <DeleteIcon className="removeWing-icon" />
                                            </div>
                                        </div>
                                        <div className="space-y-3 pt-4">
                                            {data?.floor?.map((floorData, floorId) => {
                                                return <div key={floorId}>
                                                    <div className="flex items-center justify-center space-x-4">
                                                        <input required
                                                            class="input"
                                                            type="text"
                                                            label="Floors"
                                                            name={floorData}
                                                            // value={floorData?.floorName}
                                                            id="floorname"
                                                            placeholder={`Floor ${floorId + 1}`}
                                                            onChange={(e) => handleFloorName(e, windId, floorId)} />
                                                        <button onClick={() => handleOpenAddRooms(floorId, windId)} type="button"
                                                            className="w-20 text-white bg-[#0084b5] py-1 rounded-full"><AddCircleIcon /></button>
                                                        <div className="removeFloor-wrapper cursor-pointer" onClick={() => handleFloorDelete(windId, floorId)}>
                                                            <DeleteIcon className="" />
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 space-y-4'>
                                                        {floorData?.rooms?.map((roomData, roomId) => {
                                                            return <div key={roomId} className="px-4 py-1 bg-yellow-300 mt-4 text-black text ml-5 flex justify-around">
                                                                <p className="mx-1 mt-px">{roomData?.roomName}</p>
                                                                <button
                                                                    onClick={() => handleRoomDelete(windId, floorId, roomId)}
                                                                    type='button'
                                                                    className="mb-px"><CloseIcon /></button>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            })}
                                        </div>

                                    </div>
                                    {/* <div className="removeWing-wrapper cursor-pointer">
                                    <DeleteIcon className="removeWing-icon"/>
                                </div> */}
                                </div>
                            })}
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="bg-[#0084b5] rounded text-base text-white py-2 px-4 lg:p-2 lg:m-5"
                                onClick={handleAddWingForm}>
                                <AddCircleIcon />
                                <span className="p-3">Add Wing</span>
                            </button>
                        </div>
                    </div>
                </form>
                <AddRooms
                    open={isCreatDialogOpen}
                    onClose={handleCloseDialog}
                    currentFloorId={currentFloorId}
                    currentWingId={currentWingId}
                    addRooms={addRooms}
                    infrastructureData={infrastructureData}
                />
            </div>
        </Dialog>);
}

export default AddInfrastructure;

// if (checkIfEmpty(roomData)) {
        //     infrastructureData[wingId].floor[floorId].rooms = rooms
        // }

        // [{ "building_name": "", "wing": [{ "wingName": "", "floor": [], "levelCount": "" }] }]