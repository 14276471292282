import { useState } from "react"
import AddCustomers from "./AddCustomers"
import { CUSTOMER_FIELDS } from "./CustomerInputFields"
import Spinner from '../../components/spinner';
import Errors from "../errors";
import useGetDataFromServer from "../../hooks/useGetDataFromServer";
import Pagination from "../../components/pagination";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditCustomers from "./EditCustomers";

function Clients() {
  const initialData = {
    page_no: 0,
    limit: 20,
    search_query: '',
  }
  const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isOpenEditDialog,setIsOpenEditDialog] = useState(false)
  const [singleData,setSingleData] = useState({})
  const handleOpenCreateCustomer = () => {
    setIsCreateDialogOpen(true)
  }
  const handleCloseDialog = () => {
    setIsCreateDialogOpen(false)
    setIsOpenEditDialog(false)
  }
  const { handleGetData, paramsObject } = useGetDataFromServer('getClientlist', initialData)
  const handleOpenEditModal = (id) => {
    setSingleData(handleGetData.data.data.find(x => x.id === id))
    setIsOpenEditDialog(true)
  }
  const handlePageClick = () => { }
  return (
    <>
      <div className="py-4 border-t border-gray-300">
        <div className="flex items-center space-x-4">
          <div className="flex-1">
            <input type="text" className="input2" name="email" placeholder="Search Clients..."></input>
          </div>
          <button className="btn3" onClick={handleOpenCreateCustomer}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <span>Add Client</span>
          </button>
        </div>
      </div>
      {handleGetData.isLoading ? <Spinner />
        : handleGetData.isError ? <Errors errorObject={handleGetData?.error} inline />
          : <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Sr.no
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Name
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Email
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Business Name
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Business Email
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Status
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {handleGetData?.data.data.map((supplierData, idx) => {
                  const { name, email, business_name, business_email, id } = supplierData
                  return <tr key={idx}>
                    <td className="border-dashed border-t border-gray-200 userId">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                        {(paramsObject.limit * (paramsObject.page_no + 1)) - (paramsObject.limit - (idx + 1))}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{name}</span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{email}</span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{business_name}</span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{business_email}</span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <select className="text-sm text-gray-700">
                        <option value="">--Select Status--</option>
                        <option value="ACTIVE">Active</option>
                        <option value="SUSPENDED">Suspended</option>
                        <option value="ADMIN_DISABLED">Admin disabled</option>
                        <option value="DISABLED">Disabled</option>
                      </select>
                    </td>
                <td className="border-dashed border-t border-gray-200 firstName">
                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                    <span data-id='1'><EditIcon onClick={() => handleOpenEditModal(id)}/></span>
                    <DeleteIcon/>
                  </span>
                </td>
                  </tr>
                })}

              </tbody>
            </table>
          </div>
          <div className=" my-7">
          <Pagination currentPage={paramsObject.page_no} pageCount={handleGetData?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick}/>
        </div>
        </>
          }
      <AddCustomers url={'/createClient'} fields={CUSTOMER_FIELDS} open={isCreatDialogOpen} onClose={handleCloseDialog} customerType={'client'} />
      {isOpenEditDialog && <EditCustomers url={'/createClient'} singleData={singleData} fields={CUSTOMER_FIELDS} open={isOpenEditDialog} onClose={handleCloseDialog} customerType={'client'}/>}
    </>
  )
}

export default Clients