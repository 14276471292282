import { Suspense } from "react"
import { Canvas, useLoader } from '@react-three/fiber'
import { OrbitControls, BakeShadows, Stage,Float } from '@react-three/drei'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Model = () => {
    const gltf = useLoader(GLTFLoader, "./test.gltf");
    return (
        <>
            <primitive object={gltf.scene} scale={10} />
        </>
    );
};
function Cad() {
    return (
        <>
            <div className="modal w-[40rem] h-[40rem] mx-auto bg-[#1e1e1e]">
                <Canvas shadows>
                    <Suspense>
                        {/* <ambientLight /> */}
                        <Stage intensity={0.6}>
                        <Float speed={1} floatIntensity={0.1} floatingRange={[.2, .1]} rotationIntensity={1}>
                            <Model />
                        </Float>
                        </Stage>
                        {/* <BakeShadows /> */}
                        <OrbitControls enablePan={true} enableRotate={true} enableZoom={true} />
                    </Suspense>
                </Canvas>
            </div>
        </>
    )
}

export default Cad