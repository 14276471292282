import React, { useState } from 'react'
import spinner from '../../assets/images/spinner.gif'

function ResetPassword() {
  const [isLoading, setisLoading] = useState(false)
  const [error, setError] = useState('')
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
        <div className="flex flex-col items-center justify-center w-full flex-1 px-20 ">
          <div className="bg-white rounded-2xl shadow-2xl w-2/3 max-w-lg">
            <div className="py-20 px-9">
              <p className=" text-3xl font-semibold mb-10 lato text-center">Reset Password</p>
              <form className=" space-y-4">
                <div>
                  <label for="password" className="label">New Password</label>
                  <input type="password" required
                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                    name="password" placeholder="Enter your new password here"></input>
                </div>
                <div>
                  <label for="password" className="label">Confirm New Password</label>
                  <input type="password" required
                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                    name="confirmpassword" placeholder="Enter confirm password here"></input>
                </div>
                {error && <div className='text-red-700 text-center'>
                  <small>Email or password is incorrect</small>
                </div>}
                {isLoading ? <div className=' w-8 h-8 mx-auto'>
                  <img src={spinner} alt="spinner" className='w-full h-full' />
                </div> : <div className="button">
                  <button type="submit" className=" w-full py-2 bg-[color:var(--color1)] text-white rounded lato">Reset Password</button>
                </div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword