import React, { Component } from 'react'
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import { useState } from 'react';
import Spinner from '../../components/spinner';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import { useContext } from 'react';
import AlertContext from '../../context/alerts';
import useDownloadXlsxFile from '../../hooks/useDownloadXlsxFile';


function SubmitModal({ open, onClose, handleGetData }) {
    const { sendAlert } = useContext(AlertContext)

    const getYearList = useGetDataFromServer("getYearList");
    const listCategory = useGetDataFromServer("listCategory");


    const { setDataToServer, error } = usePostDataToServer("/getProductAmountReport", "", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
        }
    })
    const [yearData, setYearData] = useState({
        year_id: "",
        category_id: "",

    })

    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }

    const handleInputs = (e) => {
        let value = e.target.value;
        let data = {
            ...yearData,
            [e.target.name]: value,
        };
        setYearData(data);
    }


    const { reportDownload } = useDownloadXlsxFile({ url: "/getProductAmountReport" })
    const handleDownloadReport = (e) => {
        e.preventDefault()
        const el = document.getElementById('year_id_' + yearData?.year_id)
        let data = {
            year_id: yearData?.year_id,
            category_id: yearData?.category_id,
            filename: el.textContent + "_Product_Valuation.xlsx"
        }
        reportDownload.mutate(data)
    }

    return (<Dialog maxWidth={'lg'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className='bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>Submit</h1>
                </div>
                <div>
                    <div onClick={onClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <form onSubmit={(e) => handleDownloadReport(e)}>
                <div className='flex space-x-20 justify-center'>
                    <div className="flex flex-col items-center mt-5">
                        <label className="block text-xl lato mb-5">
                            Please Select a Year from the Dropdown
                        </label>
                        <select required onChange={handleInputs} name="year_id"  className="border-2 border-grey w-96 h-12 rounded-md">
                            <option value="">--Select Year--</option>
                            {getYearList?.handleGetData?.data?.data?.map((categoryDrop, id) => {
                                return (
                                    <option id={'year_id_' + categoryDrop?.year_id} value={categoryDrop?.year_id}>
                                        {categoryDrop?.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="flex flex-col items-center mt-5">
                        <label className="block text-xl lato mb-5">Please select category from the dropdown</label>
                        <select onChange={handleInputs} name="category_id"
                            className="border-2 border-grey w-96 h-12 rounded-md">
                            <option value="">--Select Type--</option>
                            {listCategory?.handleGetData?.data?.data?.map((categoryDrop, id) => { return <option value={categoryDrop?.id}>{categoryDrop?.category_name}</option> })}
                        </select>
                    </div>

                </div>
                {
                    reportDownload.isLoading ? <div>
                        <Spinner className="flex justify-end mt-5" />
                    </div> :
                        <div className="flex justify-center mt-10 mb-5">
                            <button type='submit' className='btn3 text-white'>Download</button>
                        </div>
                }
            </form>
        </div>
    </Dialog>);
}

export default SubmitModal;