import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import FormElement from '../../components/form/FormElement';
import Spinner from '../../components/spinner';
import AlertContext from '../../context/alerts';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function AddVendor({ open, onClose, onCreate }) {
    const { sendAlert } = useContext(AlertContext)
    const initialData = {
        vendor_name: '',
        vendor_email: '',
        vendor_phone_number: '',
        address_one: '',
        address_two: '',
        city: '',
        state: '',
        pin_code: '',
        gst_no: '',
        company_name: '',
        cp_name: '',
        cp_email: '',
        cp_number: ''

    }
    const [vendorData, setVendorData] = useState(initialData);
    const { setDataToServer, error } = usePostDataToServer("/createVendor", "listVendor", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })


    const handleInputs = (e) => {
        let name = e.target.name
        let value = e.target.value
        let numRegex = new RegExp(/^[0-9]*$/)

        if (name === 'vendor_phone_number') {
            if (numRegex.test(value)) {
                let data = {
                    ...vendorData,
                    [e.target.name]: value
                }
                setVendorData(data)
            }

        } else {
            let data = {
                ...vendorData,
                [e.target.name]: value
            }
            setVendorData(data)
        }


        // let data = {
        //     ...vendorData,
        //     [e.target.name]: value
        // }
        // setVendorData(data)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        setDataToServer.mutate(vendorData)
    }
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return (<Dialog fullScreen fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)} TransitionComponent={Transition}>
        <div className='container_xxl'>
            <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                <div>
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div>
                    <h1 className='heading capitalize text-2xl'>Add Vendor</h1>
                </div>
            </div>
            <form onSubmit={(e) => handleSubmitForm(e)}>
                <div className='grid grid-cols-3 gap-x-5 mt-5 gap-y-5'>
                    <FormElement
                        onChange={handleInputs}
                        label={'Vendor Name'}
                        placeholder={'vendor name'}
                        element={'input'}
                        name={'vendor_name'}
                        autocomplete={"off"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'Vendor Email'}
                        placeholder={'vendor@email.com'}
                        element={'input'}
                        type={'email'}
                        name={'vendor_email'}
                        autocomplete={"off"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'Contact No'}
                        placeholder={'contact no'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'vendor_phone_number'}
                        value={vendorData?.vendor_phone_number}
                        maxlength={"10"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'Address 1'}
                        placeholder={'address'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'address_one'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'Address 2'}
                        placeholder={'address'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'address_two'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'State'}
                        placeholder={'state'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'state'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'City'}
                        placeholder={'city'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'city'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'Pincode'}
                        placeholder={'pincode'}
                        element={'input'}
                        type={'number'}
                        autocomplete={"off"}
                        onWheel={numberInputOnWheelPreventChange}
                        name={'pin_code'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'GST No'}
                        placeholder={'GST no'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'gst_no'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'Company Name'}
                        placeholder={'company name'}
                        element={'input'}
                        name={'company_name'}
                        autocomplete={"off"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                </div>
                <div className='grid grid-cols-3 gap-x-5 gap-y-5 mt-12'>
                    <FormElement
                        onChange={handleInputs}
                        label={'Contact Person Name'}
                        placeholder={'contact person name'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'cp_name'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'Contact Person Email'}
                        placeholder={'cp@email.com'}
                        element={'input'}
                        type={'email'}
                        autocomplete={"off"}
                        name={'cp_email'} />
                    <FormElement
                        onChange={handleInputs}
                        label={'Contact Person Contact'}
                        placeholder={'contact person contact'}
                        maxlength={"10"}
                        autocomplete={"off"}
                        element={'input'}
                        type={'tel'}
                        name={'cp_number'} />
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> : <div className="flex justify-center mt-5">
                        <button type='submit' className='text-white btn3'>Create</button>
                    </div>}
            </form>
            {error && <small className="text-red-700 font-semibold mt-1">{setDataToServer?.error?.response?.data?.message}</small>}
        </div>
    </Dialog>
    )
}
export default AddVendor;