import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import FormElement from '../../components/form/FormElement';
import CloseIcon from '@mui/icons-material/Close';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import AlertContext from "../../context/alerts";
import { useParams } from 'react-router-dom';
import { checkIfNotEmpty } from '../../utility';
import Spinner from '../../components/spinner';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useRef } from 'react';
import { displayProductDetails } from '../../apis/productlistapi';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function UpdateStats({ onCloseUpdateStats, open, yearData, data }) {
    const { roomname, roomid } = useParams();
    const [updateData, setUpdateData] = useState(data);
    const [isChanged, setIsChanged] = useState(false)

    const { handleGetData } = useGetDataFromServer("listProduct")
    const listCategory = useGetDataFromServer("listCategory")
    const { sendAlert } = useContext(AlertContext)

    const { setDataToServer, error } = usePostDataToServer("/updateStats ", "", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onCloseUpdateStats()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })
    const handleInputs = (e) => {
        let value = e.target.value
        let data = {
            ...updateData,
            [e.target.name]: value
        }
        setUpdateData(data)
        setIsChanged(true)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        const data = {
            product_id: updateData?.product_id,
            initial_quantity: updateData?.initial_quantity,
            floor_room_id: roomid,
            final_quantity: updateData?.final_quantity,
            remarks: updateData?.remarks,
            year_id: yearData?.year_id,
            stats_id: updateData.stats_id
        }
        setDataToServer.mutate(data)
    }
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onCloseUpdateStats()
    }



    // scroll code here

    const [roomDropName, setRoomName] = useState([])
    const [pageNoBuilding, setPageNoBuilding] = useState(0);
    const [previousState, setPreviousState] = useState("");
    const initialLoadBuilding = useRef(false)

    const formatData = (arr, valueName, labelName) => {
        let newarr = []
        for (let i = 0; i < arr?.length; i++) {
            newarr.push({ value: arr[i]?.[valueName], label: arr[i]?.[labelName], ...arr[i] })
        }
        return newarr;
    }

    const getProductName = async () => {
        const res = await displayProductDetails();
        const res2 = formatData(res.data.data, 'product_id', 'product_name')
        setRoomName(res2);
    }
    useEffect(() => { getProductName() }, [])

    const loadBuildingNames = (search, prevOptions) => {
        const formatDataFn = (resp) => {
            return formatData(resp, 'product_id', 'product_name')
        }
        return loadOptions(search, prevOptions,
            initialLoadBuilding,
            roomDropName, setRoomName,
            pageNoBuilding, setPageNoBuilding, formatDataFn)
    }

    const loadOptions = async (search, prevOptions, initialLoad, state, setState, pageNo, setPageNo, formatDataFn) => {
        try {
            if (!initialLoad.current && !search) {
                initialLoad.current = true;
                return {
                    options: state,
                    hasMore: true
                };
            }
            else {
                let newPage;
                if (checkIfNotEmpty(search) && previousState !== search) {
                    newPage = 0;
                    setPageNo(newPage);
                } else {
                    // newPage = pageNo;
                    newPage = pageNo + 1;
                    setPageNo(newPage);
                }

                let res = await displayProductDetails(newPage, search)
                newPage = pageNo + 1;
                setPageNo(newPage);

                const data = state.concat(res?.data?.data)
                // setState(data)
                let temps = res.data.data;

                let hasMore = true;
                if (res?.data?.data?.length == 0) {
                    hasMore = false;
                }
                let arr = formatDataFn(res?.data?.data);
                setPreviousState(search)

                return {
                    options: arr,
                    hasMore: hasMore
                };
            }
        }
        catch (err) {
            // console.log(err)
        }
    }


    useEffect(() => {
        if (checkIfNotEmpty(data)) {
            setUpdateData(data)
        }
    }, [data])


    const nameFunction = () => {

        let name = roomDropName?.find(el => {
            return parseInt(el.product_id) == parseInt(updateData?.product_id)
        })

        if (checkIfNotEmpty(name)) {
            return name
        } else {
            return {
                id: updateData?.product_id,
                value: updateData?.product_id,
                product_name: updateData?.product_name,
                label: updateData?.product_name
            }
        }
    }

    const handleProductName = (e) => {
        let data = {
            ...updateData,
            product_id: e.product_id,
            product_name: e.product_name
        }
        setUpdateData(data)
        setIsChanged(true)
    }

    return (<Dialog fullScreen fullWidth={true} open={open} onClose={handleClearAndCloseForm} TransitionComponent={Transition}>
        <div className='container_xxl'>
            <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                <div>
                    <IconButton onClick={handleClearAndCloseForm}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div>
                    <h1 className='heading capitalize text-2xl'>Update Stats</h1>
                </div>
            </div>
            <form className='py-7' onSubmit={(e) => handleSubmitForm(e)}>
                <div className='grid grid-cols-3 gap-x-9 gap-y-6'>
                    <div className=''>
                        <label className="block text-xl lato mb-px">Product</label>
                        {/* <select required name="product_id" value={updateData?.product_id}
                            onChange={handleInputs}
                            className="border-2 border-grey w-full h-12 rounded-md">
                            <option value="">--Select Type--</option>
                            {handleGetData?.data?.data?.map((categoryDrop, id) => { return <option value={categoryDrop?.product_id}>{categoryDrop?.product_name}</option> })}
                        </select> */}
                        <AsyncPaginate
                            value={nameFunction()}
                            // options={buildingName}
                            isSearchable={true}
                            lab
                            loadOptions={loadBuildingNames}
                            onChange={(e) => handleProductName(e)}
                        />
                    </div>
                    {/* <div className=''>
                        <label className="block text-xl lato mb-px">Category</label>
                        <select required onChange={handleInputs} name="category" value={updateData?.category_id}
                            className="border-2 border-grey w-full h-12 rounded-md">
                            <option value="">--Select Type--</option>
                            {listCategory?.handleGetData?.data?.data?.map((categoryDrop, id) => { return <option value={categoryDrop?.id}>{categoryDrop?.category_name}</option> })}
                        </select>
                    </div> */}

                    <div className="mt-1">
                        <FormElement
                            label={"Initial Quantity"}
                            onChange={handleInputs}
                            placeholder={'Quantity'}
                            autocomplete={"off"}
                            element={'input'}
                            value={updateData?.initial_quantity}
                            name={'initial_quantity'}
                            required={'required'}
                            errorMessage={'This is a Required Field'} />
                    </div>
                    <div className="mt-1">
                        <FormElement
                            label={"Final Quantity"}
                            onChange={handleInputs}
                            value={updateData?.final_quantity}
                            placeholder={'Quantity'}
                            autocomplete={"off"}
                            element={'input'}
                            name={'final_quantity'}
                            required={'required'}
                            errorMessage={'This is a Required Field'} />
                    </div>
                </div>
                <div className="mt-5">
                    <FormElement
                        label={"Remarks"}
                        value={updateData?.remarks}
                        onChange={handleInputs}
                        placeholder={'Remarks'}
                        autocomplete={"off"}
                        element={'textarea'}
                        name={'remarks'} />
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> : isChanged == true ?
                        <div className="flex justify-center mt-10">
                            <button type='submit' className='btn3 text-white'>Edit Stats</button>
                        </div> : <div className="flex justify-center mt-10">
                            <button disabled type='submit' className='btn3 text-white'>Edit Stats</button>
                        </div>}
            </form>
        </div>
    </Dialog>);
}

export default UpdateStats;