import React, { useContext, useRef, useState } from 'react';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import AlertContext from "../../context/alerts";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect } from 'react';
import { checkIfNotEmpty } from '../../utility';
import { AsyncPaginate } from 'react-select-async-paginate';
import "./RoomUpdate.scss";
import { displayRoomDetails } from '../../apis/roomslistapi';


function RoomUpdate({ roomData, roomId, windId, apiFloorId, floorId, handleRoomUpdate, setInfrastructureData, handleRoomDelete, isAdd, changeAddRoomDetails, updateRooms }) {
    const { sendAlert } = useContext(AlertContext)

    const [roomDropName, setRoomName] = useState([])
    const [pageNoBuilding, setPageNoBuilding] = useState(0);
    const initialLoadBuilding = useRef(false)
    const [previousState, setPreviousState] = useState("");


    const formatData = (arr, valueName, labelName) => {
        let newarr = []
        for (let i = 0; i < arr?.length; i++) {
            newarr.push({ value: arr[i]?.[valueName], label: arr[i]?.[labelName], ...arr[i] })
        }
        return newarr;
    }

    // const { handleGetData, paramsObject } = useGetDataFromServer("listingRoomType", "", "", {
    //     onSuccessCb: (data) => {
    //         const res2 = formatData(data?.data, 'id', 'type_name')
    //         setRoomName(res2);

    //     },
    //     onErrorCb: (err) => {
    //         console.log('error', err)
    //     }
    // })


    const [roomUpdate, setRoomUpdate] = useState(roomData)


    useEffect(() => {
        if (checkIfNotEmpty(roomData)) {
            setRoomUpdate(roomData)
        }
    }, [roomData])

    const getBuildingName = async () => {
        const res = await displayRoomDetails();
        const res2 = formatData(res.data.data, 'id', 'type_name')
        setRoomName(res2);
    }

    useEffect(() => { getBuildingName() }, [])

    const loadOptions = async (search, prevOptions, initialLoad, state, setState, pageNo, setPageNo, formatDataFn, roomUpdate) => {
        try {
            if (!initialLoad.current && !search) {
                initialLoad.current = true;
                return {
                    options: state,
                    hasMore: true
                };
            }
            else {
                let newPage;
                if (checkIfNotEmpty(search) && previousState !== search) {
                    newPage = 0;
                    setPageNo(newPage);
                } else {
                    newPage = pageNo + 1;
                    setPageNo(newPage);
                }

                let res = await displayRoomDetails(newPage, search)
                newPage = pageNo + 1;
                setPageNo(newPage);

                const data = state.concat(res?.data?.data)
                // setState(data)
                let temps = res?.data?.data

                let hasMore = true;
                if (res?.data?.data.length == 0) {
                    hasMore = false;
                }



                let arr = formatDataFn(res?.data?.data);


                // console.log('roomDropName :>> ', roomDropName, formatDataFn(res?.data?.data));

                let newcopy = roomDropName.slice()

                // console.log('newcopy :>> ', newcopy);
                let newarr = newcopy.concat(arr)


                // console.log('newarr :>> ', newarr);


                
                setState(newarr)



                let isPresent = arr.some((el) => {
                    return parseInt(el.roomType) == parseInt(roomUpdate?.roomType)
                })

                // if (!isPresent) {
                //     arr.push({
                //         id: roomUpdate?.roomType,
                //         value: roomUpdate?.roomType,
                //         type_name: roomUpdate?.roomType_name,
                //         label: roomUpdate?.roomType_name
                //     })
                // }

                setPreviousState(search)

                return {
                    options: arr,
                    hasMore: hasMore
                };
            }
        }
        catch (err) {
            // console.log(err)

        }
    }

    const loadBuildingNames = (search, prevOptions) => {
        const formatDataFn = (resp) => {
            return formatData(resp, 'id', 'type_name')
        }
        return loadOptions(search, prevOptions,
            initialLoadBuilding,
            roomDropName, setRoomName,
            pageNoBuilding, setPageNoBuilding, formatDataFn, roomUpdate)
    }

    const handleRoomInputs = (e, wingId, floorId, roomId) => {
        let value = e.target.value;
        let data = { ...roomUpdate }
        if (e.target.name === "roomName") {
            data.roomName = value
        } else {

            data.roomType = value
        }

        setRoomUpdate(data)
        handleRoomUpdate(roomId, apiFloorId, data)
    }

    const handleBuildingName = (e) => {
        let data = {
            ...roomUpdate,
            roomType: e.id
        }
        setRoomUpdate({ ...data })
        handleRoomUpdate(roomId, apiFloorId, data)
    }

    const nameFunction = () => {
        // console.log('object data callled for testing :>> ');
        // console.log('roomDropName :>> ', roomDropName.length);


        let name = roomDropName?.find(el => {
            return parseInt(el.id) == parseInt(roomUpdate?.roomType)
        })

        // console.log('name :>> ', name);
        if (checkIfNotEmpty(name)) {
            return name
        } else {
            return {
                id: roomUpdate?.roomType,
                value: roomUpdate?.roomType,
                type_name: roomUpdate?.roomType_name,
                label: roomUpdate?.roomType_name
            }
        }
    }

    return (<>
        <div className="w-full h-44 border-solid border-2 border-gray-500 mt-4 text-black text">
            <div className='flex justify-center mt-11 mx-auto space-x-4'>
                <input required className='w-60 h-12 border-2 border-grey px-2 rounded-md'
                    name='roomName'
                    placeholder='Room Name'
                    value={roomUpdate?.roomName}
                    onChange={(e) => handleRoomInputs(e, windId, floorId, roomId)}>
                </input>
                <div className='w-60'>

                    {/* <select onChange={(e) => handleRoomInputs(e, windId, floorId, roomId)} name="roomType" value={parseInt(roomUpdate?.roomType)}
                        className="border-2 border-grey w-60 h-12 rounded-md">
                        <option>--Select Type--</option>
                        {handleGetData?.data?.data?.map((roomDrop, id) => { return <option value={roomDrop?.id}>{roomDrop?.type_name}</option> })}
                    </select> */}
                    <AsyncPaginate
                        value={nameFunction()}
                        // options={buildingName}
                        isSearchable={true}
                        lab
                        loadOptions={loadBuildingNames}
                        onChange={(e) => handleBuildingName(e)}
                    />
                </div>
                <div className='flex space-x-4 mt-2 justify-center'>
                    <div onClick={(e) => handleRoomDelete(e, roomData?.roomId)} className="removeFloor-wrapper cursor-pointer mt-1"><DeleteIcon /></div>
                </div>
            </div>
        </div>
    </>);
}

export default RoomUpdate;