import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import CloseIcon from '@mui/icons-material/Close';
import AlertContext from '../../context/alerts';
import FormElement from '../../components/form/FormElement';
import Spinner from '../../components/spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function AddUser({ open, onClose }) {
    const { sendAlert } = useContext(AlertContext)
    const initialData = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: ''
    }
    const [roomTypeData, setRoomTypeData] = useState(initialData);
    const { setDataToServer, error } = usePostDataToServer("/register", "getUserList", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const handleInputs = (e) => {
        let name = e.target.name
        let value = e.target.value
        let numRegex = new RegExp(/^[0-9]*$/)

        if(name === 'phone'){
            if (numRegex.test(value)) {
                let data = {
                    ...roomTypeData,
                    [e.target.name]: value
                }
                setRoomTypeData(data)
            }

        }else{
            let data = {
                ...roomTypeData,
                [e.target.name]: value
            }
            setRoomTypeData(data)
        }

    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        setDataToServer.mutate(roomTypeData)
    }
    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    // const numCheck = () => {
    //     let phoneRegex = /^([+]?[0-9-?]{5,21})+$/;
    //     let phoneError = "Please enter your phone number.";

    //     if (!phoneRegex.test(roomTypeData?.phone)) {
    //         sendAlert(phoneError, "WARNING")
    //         return
    //     }
    // }



    return (<Dialog fullScreen fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)} TransitionComponent={Transition}>
        <div className='container_xxl'>
            <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                <div>
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div>
                    <h1 className='heading capitalize text-2xl'>Add User</h1>
                </div>
            </div>
            <form onSubmit={(e) => handleSubmitForm(e)} className="py-7" autoComplete='off'>
                <div className='grid grid-cols-3 gap-x-5 mt-5 gap-y-5'>
                    <FormElement
                        label={'First Name'}
                        onChange={handleInputs}
                        placeholder={'e.g.John'}
                        element={'input'}
                        name={'first_name'}
                        autocomplete={"off"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        label={'Last Name'}
                        onChange={handleInputs}
                        placeholder={'e.g.Doe'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'last_name'}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        label={'Phone'}
                        onChange={handleInputs}
                        placeholder={'e.g.123456789'}
                        element={'input'}
                        autocomplete={"off"}
                        value={roomTypeData?.phone}
                        name={'phone'}
                        maxLength={10}
                        // onWheel={numberInputOnWheelPreventChange}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        label={'Email'}
                        onChange={handleInputs}
                        placeholder={'e.g.admin@mail.com'}
                        element={'input'}
                        type={'email'}
                        name={'email'}
                        autocomplete={"off"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        label={'Password'}
                        onChange={handleInputs}
                        element={'input'}
                        type={'password'}
                        name={'password'}
                        autocomplete={"new-password"}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> :
                    <div className="flex justify-center mt-5">
                        <button type='submit' className='text-white btn3'>Create</button>
                    </div>}
                {error && <small className="text-red-700 text-base font-semibold mt-5 flex justify-center">{setDataToServer?.error?.response?.data?.message}</small>}
            </form>
        </div>
    </Dialog>
    )
}
export default AddUser;