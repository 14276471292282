import React, { useContext, useState } from 'react'
import { Button, Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../components/spinner';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import { checkIfNotEmpty } from '../../utility';
import FormElement from '../../components/form/FormElement';
import AlertContext from '../../context/alerts';


function RegeneratePassword({ onClose, open, userData }) {

    const { sendAlert } = useContext(AlertContext)

    const [data, setData] = useState()

    const { setDataToServer, error } = usePostDataToServer("/PasswordReset ", "", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const handleInputs = (e) => {
        let value = e.target.value
        let newData = {
            ...data,
            [e.target.name]: value
        }
        setData(newData)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let sentData = {
            password: data?.password,
            id: userData.user_id
        }
        setDataToServer.mutate(sentData)
    }






    return (<Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={onClose}>
        <div className=' bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>Regenerate Password</h1>
                </div>
                <div>
                    <div onClick={onClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
                <FormElement
                    label={'Password'}
                    onChange={handleInputs}
                    element={'input'}
                    type={'password'}
                    name={'password'}
                    autocomplete={"off"}
                    required={'required'}
                    errorMessage={'This is a Required Field'} />

                {
                    setDataToServer.isLoading ? <div>
                        <Spinner className="flex justify-end mt-5" />
                    </div> :
                        <div className="flex justify-center mt-10 mb-5">
                            <button type='submit' className='btn3 text-white'>Regenerate</button>
                        </div>
                }
            </form>
        </div>
    </Dialog>);
}

export default RegeneratePassword;