import React, { useState, useEffect } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import CloseIcon from '@mui/icons-material/Close';
import FormElement from "../../components/form/FormElement"

function RemarkModal({ open, onClose, setAuditData, auditData, handleInputs }) {

    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }
    return (<>
        <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
            <div className='bg-white w-full p-5'>
                <div className='modal-head flex items-center justify-between'>
                    <div className='mt-1.5'>
                        <h1 className='heading capitalize text-2xl'>Remarks</h1>
                    </div>
                    <div>
                        <div onClick={onClose}>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <form>
                    <div>
                        <FormElement
                            onChange={handleInputs}
                            placeholder={'Remarks'}
                            element={'textarea'}
                            autocomplete={"off"}
                            name={'remarks'} />
                    </div>
                </form>
            </div>
        </Dialog>

    </>);
}

export default RemarkModal;