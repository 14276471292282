import Layout from "../../components/layout"
import TabNav from "../../components/TabNav"
import ContactsIcon from '@mui/icons-material/Contacts';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const Users = ({ children }) => {
    const navData = [
        { title: 'Client Master', slug: 'clients', icon:<SupervisedUserCircleIcon/> },
        { title: 'Supplier Master', slug: 'suppliers',icon: <ContactsIcon/> }
    ]
    return <Layout>
        <div className="py-4">
            <TabNav navData={navData} />
        </div>
        <div>
            {children}
        </div>
    </Layout>
}
export default Users