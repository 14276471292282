import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import CloseIcon from '@mui/icons-material/Close';
import FormElement from '../../components/form/FormElement';
import AlertContext from "../../context/alerts";
import Spinner from '../../components/spinner';
import { getDateFromTimestamp } from '../../utility';

function EditYear({ open, onClose, singleData, id }) {
    let initial = {
        start: '',
        end: '',
    }

    const [yearData, setYearData] = useState(singleData);
    const [isChanged, setIsChanged] = useState(false)

    const [newData, setNewData] = useState(initial)
    const { sendAlert } = useContext(AlertContext)
    const [isChecked, setIsChecked] = useState(singleData?.current_year === "true" ? true : false);
    const { setDataToServer, error } = usePostDataToServer("/updateYear", "getYearList", {
        onSuccessCb: (data) => {
            sendAlert(data?.message, "SUCCESS")
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const handleInputs = (e) => {
        let value = e.target.value
        let data = {
            ...yearData,
            [e.target.name]: value
        }
        setYearData(data)
        setIsChanged(true)
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        let message1 = "End year should be greater"
        let message2 = "Start & End year should not be similar"
        if (yearData.start > yearData.end) {
            sendAlert(message1, "WARNING")
            return
        } else if (yearData.start == yearData.end) {
            sendAlert(message2, "WARNING")
            return
        }
        let Data = {
            start: yearData?.start,
            end: yearData?.end,
            isCurrentYear: isChecked,
            year_id: id
        }
        setDataToServer.mutate(Data)
    }

    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        onClose()
    }
    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return (<Dialog maxWidth={'md'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className='bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>Edit Year</h1>
                </div>
                <div>
                    <div onClick={onClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <form onSubmit={(e) => handleSubmitForm(e)} className="py-7">
                <div className='flex justify-center space-x-9'>
                    <FormElement
                        label={'Start of the year'}
                        onChange={handleInputs}
                        placeholder={'e.g. 2023'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'start'}
                        type={'number'}
                        onWheel={numberInputOnWheelPreventChange}
                        value={yearData?.start}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                    <FormElement
                        label={'End of the year'}
                        onChange={handleInputs}
                        placeholder={'e.g. 2024'}
                        element={'input'}
                        autocomplete={"off"}
                        name={'end'}
                        type={'number'}
                        onWheel={numberInputOnWheelPreventChange}
                        value={yearData?.end}
                        required={'required'}
                        errorMessage={'This is a Required Field'} />
                </div>
                <div className='flex justify-center mt-10'>
                    <p className='text-xl lato mr-11'>Is This Current Year</p>
                    <div className='ml-5 mt-1.5'>
                        <input type="checkbox"
                            checked={isChecked}
                            value={yearData?.current_year === "true" ? true : false}
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> : isChanged == true ?
                        <div className="flex justify-center mt-5">
                            <button type='submit' className='text-white btn3'>Edit</button>
                        </div> : <div className="flex justify-center mt-5">
                            <button disabled type='submit' className='text-white btn3 bg-blue-300'>Edit</button>
                        </div>}
                {error && <small className="text-red-700 font-semibold mt-1">{setDataToServer?.error?.response?.data?.message}</small>}
            </form>
        </div>
    </Dialog>);
}

export default EditYear;