import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import axios from "../apis/axios";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../config";
const cookies = new Cookies();



function usePostDataToServer(url, refreshUrl, cbObj) {
    const queryClient = useQueryClient()
    let onSuccessCb = cbObj?.onSuccessCb
    let onErrorCb = cbObj?.onErrorCb
    const [error, onError] = useState()
    const [success, onSuccess] = useState()
    const setDataToServer = useMutation((data) => setUsers(data), {
        onError: (err) => {
            onError(true)
            if (onErrorCb instanceof Function) {
                onErrorCb(err)
            }
        },
        onSuccess: (data) => {
            onSuccess(true)
            queryClient.invalidateQueries(refreshUrl)
            if (onSuccessCb instanceof Function) {
                onSuccessCb(data)
            }
        }
    })
    const setUsers = async (getFormData) => {
        let TOKEN = cookies.get(TOKEN_NAME);
        let fd = new FormData()
        for (var key in getFormData) {
            if (key.endsWith("[]")) {
                for (let i = 0; i < getFormData[key].length; i++) {
                    fd.append(key, getFormData[key][i]);
                }
            } else {
                fd.append(key, getFormData[key]);
            }


        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: url,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { setDataToServer, error, onError, success, onSuccess }
}

export default usePostDataToServer