
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from './pages/auth/Login'
import ResetPassword from "./pages/auth/ResetPassword";
import Home from "./pages/home/Home";
import AssembledProduct from "./pages/products/AssembledProduct";
import SingleProduct from "./pages/products/SingleProduct";
import Single from "./pages/single/Single";
import Clients from "./pages/users/Clients";
import Suppliers from "./pages/users/Suppliers";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Billing from "./pages/billing";
import Sales from "./pages/billing/Sales";
import Purchase from "./pages/billing/Purchase";
import Users from "./pages/users";
import ProtectedRoutes from './routes/ProtectedRoutes'
import Cad from "./pages/Cad";
import Infrastructure from "./pages/infrastructure/Infrastructure";
import RoomMaster from "./pages/roommaster/RoomMaster"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import CategoryMaster from "./pages/categorymaster/CategoryMaster";
import VendorMaster from "./pages/VendorMaster/VendorMaster";
import { AlertContextProvider } from "./context/alerts";
import ToastContainer from "./components/ToastContainer/ToastContainer";
import PurchaseMaster from "./pages/Purchase/PurchaseMaster";
import YearMaster from "./pages/Year/YearMaster";
import StatsMaster from "./pages/classroomStats/StatsMaster";
import RoomInventoryData from "./pages/classroomStats/RoomInventoryData";
import ProductAssets from "./pages/productAssets/ProductAssets";
import DeductProduct from "./pages/deductproduct/DeductProduct";
import UserMaster from "./pages/AddUsers/UserMaster";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
})
function App() {

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AlertContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route path="login" element={<Login />} />
                <Route path="forgot_password" element={<ForgotPassword />} />
                <Route path="reset_password" element={<ResetPassword />} />
                <Route path="/" element={<ProtectedRoutes><Home /></ProtectedRoutes>} />
                <Route path="/clients" element={<ProtectedRoutes><Users><Clients /></Users></ProtectedRoutes>} />
                <Route path="/suppliers" element={<ProtectedRoutes><Users><Suppliers /></Users></ProtectedRoutes>} />
                <Route path="/single" element={<ProtectedRoutes><Single /></ProtectedRoutes>} />
                <Route path="/infrastructure" element={<ProtectedRoutes><Infrastructure /></ProtectedRoutes>} />
                <Route path="/rooms" element={<ProtectedRoutes><RoomMaster /></ProtectedRoutes>} />
                <Route path="/single-product" element={<ProtectedRoutes><SingleProduct /></ProtectedRoutes>} />
                <Route path="/assembled-product" element={<ProtectedRoutes><AssembledProduct /></ProtectedRoutes>} />
                {/* <Route path="/purchase" element={<ProtectedRoutes><Billing><Purchase /></Billing></ProtectedRoutes>} />
                <Route path="/sales" element={<ProtectedRoutes><Billing><Sales /></Billing></ProtectedRoutes>} /> */}
                <Route path="/cad" element={<Cad />} />
                <Route path="/category" element={<ProtectedRoutes><CategoryMaster /></ProtectedRoutes>} />
                <Route path="/vendor" element={<ProtectedRoutes><VendorMaster /></ProtectedRoutes>} />
                <Route path="/purchase" element={<ProtectedRoutes><PurchaseMaster /></ProtectedRoutes>} />
                <Route path="/year" element={<ProtectedRoutes><YearMaster /></ProtectedRoutes>} />
                <Route path="/stats" element={<ProtectedRoutes><StatsMaster /></ProtectedRoutes>} />
                <Route path="/roominventorydata/:roomname/:roomid" element={<ProtectedRoutes><RoomInventoryData /></ProtectedRoutes>} />
                <Route path="/assets" element={<ProtectedRoutes><ProductAssets /></ProtectedRoutes>} />
                <Route path="/deductproduct/:id" element={<ProtectedRoutes><DeductProduct /></ProtectedRoutes>} />
                <Route path="/users" element={<ProtectedRoutes><UserMaster /></ProtectedRoutes>} />
              </Route>
            </Routes>
          </BrowserRouter>
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} />
        </AlertContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
