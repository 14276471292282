
import React, { useState } from 'react'
import { useContext } from "react";
import AlertContext from "../../context/alerts"
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


function ToastContainer() {
  const { dismissAlert, alerts } = useContext(AlertContext);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  return (<>
    <div className="fixed z-[3000] top-0 right-0">
      <div className={
        alerts?.arr?.some((el) => {
          return !el?.isHidden;
        }) ? 'm-10 ' : '' + ''}
      >
        {alerts?.arr?.map((al, idx) => {
          return <div>
            {al.type === "SUCCESS" ? <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={!al.isHidden} onClose={dismissAlert} autoHideDuration={5000}>
              <Alert onClose={dismissAlert} severity="success" sx={{ width: '100%' }}>
                {al.message}
              </Alert>
            </Snackbar> : al.type === "ERROR" ? <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={!al.isHidden} onClose={dismissAlert} autoHideDuration={5000}>
              <Alert onClose={dismissAlert} severity="error" sx={{ width: '100%' }}>
                {al.message}
              </Alert>
            </Snackbar> : al.type === "WARNING" ? <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={!al.isHidden} onClose={dismissAlert} autoHideDuration={5000}>
              <Alert onClose={dismissAlert} severity="warning" sx={{ width: '100%' }}>
                {al.message}
              </Alert>
            </Snackbar> : ""
            }
          </div>

          //<div
          //   className={
          //     'bg-white drop-shadow-lg px-6 py-3 items-center space-x-2 mb-5 transition-all duration-500 ' +
          //     (al.isInvisible ? 'opacity-0 ' : 'opacity-100  ') +
          //     (al.isHidden ? 'hidden ' : 'flex  ')
          //   }
          // >

          {/* <CheckIcon
              v-if="al.type === 'SUCCESS'"
              class="w-6 h-6"
            />
            <ErrorIcon
              v-if="al.type === 'ERROR'"
              class="w-6 h-6"
            />
            <WarningIcon
              v-if="al.type === 'WARNING'"
              class="w-6 h-6"
            />
            <InformationIcon
              v-if="'MESSAGE' === al.type"
              class="w-6 h-6"
            /> */}
          // <p>{al.message}</p>
          // <button onClick={() => dismissAlert(al.id)}>
          //   <CloseIcon />
          // </button>
          //</div >
        })}
      </div >
    </div >

  </>);
}

export default ToastContainer;



