import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import Spinner from '../../components/spinner';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RoomSection from './RoomSection';
import EditIcon from '@mui/icons-material/Edit';
import Errors from '../errors';
import Pagination from '../../components/pagination';
import { checkIfEmpty } from '../../utility';

function FloorSection({ wingId, data, goToWings }) {

    let id = {
        screen_id: wingId,
        screen: "FLOOR",
        pageNo: 0,
        limit: 10,
    }
    const [floorId, setFloorId] = useState()
    const [section, setSection] = useState(4);
    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer("getBuildingList", id)

    const goToSection = (sectionNo, floorId) => {
        setSection(sectionNo);
        setFloorId(floorId)
    }


    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }


    return (<>
        {section === 4 && <>
            <div onClick={goToWings}>
                <IconButton>
                    <ArrowBackIcon />
                </IconButton>
            </div>
            <div className='text-2xl font-semibold flex justify-center'>
                <span >FLOORS</span>
            </div>
            {handleGetData.isLoading ? <Spinner />
                : handleGetData?.data?.data <= 0 || checkIfEmpty(handleGetData?.data?.data) ? <div className="flex items-center justify-center h-96">
                    <p className="text-lg font-semibold">No Floors Available</p>
                </div> : handleGetData.isError ? <Errors errorObject={handleGetData?.error} inline />
                    : <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-6">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Sr.no
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Floor Name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Total Rooms
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {handleGetData?.data?.data?.map((floorData, idx) => {
                                    const { floorId, floor_name, total_rooms } = floorData
                                    return <tr key={idx}>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                            </span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{floor_name}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{total_rooms}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                                                <span data-id='1'><EditIcon onClick={() => goToSection(5, floorId)} /></span>
                                            </span>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>}
            <div className="my-7">
                <Pagination currentPage={paramsObject?.pageNo} pageCount={handleGetData?.data?.total_count} limit={paramsObject.limit} onPageChange={handlePageClick} />
            </div>
        </>}
        {section === 5 && <RoomSection data={handleGetData.data.data} floorId={floorId} goToFloors={() => goToSection(4)} />}
    </>);
}

export default FloorSection;