// export const handleGetFeedBackData = (id) => {
//     setSingleLeaveData(leaveList.find(data => data.id === id))
// } 

export const checkIfEmpty = (value) => {
    return value === null || value === undefined || value === ''
}
export const checkIfNotEmpty = (value) => {
    return value !== null && value !== undefined && value !== ''
}
export const checkIfObjectNotEmpty = (val) => {
    let values = Object.values(val)
    let isEmpty = false
    for (let i = 0; i < values.length; i++) {
        if (checkIfEmpty(values[i])) {
            isEmpty = true
            break;
        }
    }
    return !isEmpty;
}
export const getDateFromTimestamp = (timestamp) => {
    let d = new Date(parseInt(timestamp) * 1000);
    let month

    switch (d.getUTCMonth()) {
        case 0:
            month = "January";
            break;
        case 1:
            month = "February";
            break;
        case 2:
            month = "March";
            break;
        case 3:
            month = "April";
            break;
        case 4:
            month = "May";
            break;
        case 5:
            month = "June";
            break;
        case 6:
            month = "July";
            break;
        case 7:
            month = "August";
            break;
        case 8:
            month = "September";
            break;
        case 9:
            month = "October";
            break;
        case 10:
            month = "November";
            break;
        case 11:
            month = "December";
            break;
        default:
            break;
    }
    return month + " " + d.getUTCDate() + ", " + d.getFullYear();
}