import { useState } from "react"

function FormElement(props) {
    const { label, element = 'input', onChange, errorMessage, optionValue, ...inputProps } = props
    const [focused, setFocused] = useState(false)
    const handleFocused = () => {
        setFocused(true)
    }
    const elements = () => {
        switch (element) {
            case 'input':
                return <input {...inputProps} className='input' focused={focused.toString()} onChange={onChange} onBlur={handleFocused} />
            case 'select':
                return <select {...inputProps} className='input' focused={focused.toString()} onChange={onChange} onBlur={handleFocused}>
                    <option value="">--Select--</option>
                    {optionValue?.map((item, idx) => {
                        return <option key={idx} value={item.value}>{item.name}</option>
                    })}
                </select>
            case 'textarea':
                return <textarea {...inputProps} className='input min-h-[15rem]' onChange={onChange} focused={focused.toString()} onBlur={handleFocused}></textarea>
            default:
                break;
        }
    }
    return (
        <div>
            <label htmlFor={label} className='label'>{label}</label> {props.required && <span className='mandatory'>*</span>}
            {elements()}
            <small className="text-red-700 font-semibold errorMessage mt-1">{errorMessage}</small>
        </div>
    )
}

export default FormElement