import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../components/layout';
import Spinner from '../../components/spinner';
import Errors from "../errors";
import useGetDataFromServer from "../../hooks/useGetDataFromServer";
import Pagination from "../../components/pagination";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddRoomType from "../../components/AddRoomType/AddRoomType"
import { checkIfEmpty, checkIfNotEmpty, getDateFromTimestamp } from '../../utility';
import DeleteItem from '../../components/DeleteItem/DeleteItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CreatePurchaseProduct from './CreatePurchaseProduct';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditPurchaseProduct from './EditPurchaseProduct';
import AlertContext from '../../context/alerts';
import useDownloadFile from '../../hooks/useDownloadFile';
import spinner from "../../assets/images/spinner.gif"
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditBillModal from './EditBillModal';
import deBounce from '../../hooks/useDebounce';


function PurchaseMaster() {
    const { sendAlert } = useContext(AlertContext)

    const initialData = {
        pageNo: 0,
        limit: 10,
        searchQuery: '',
    }
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
    const [open, setOpen] = useState(false);

    const [openBillModal, setOpenBillModal] = useState(false)

    const [singleData, setSingleData] = useState({})

    const [billData, setBillData] = useState({})


    const [isDeleted, setIsDeleted] = useState(false)
    const handleDeletedSuccess = () => {
        setIsDeleted(true)
        setOpen(true)
    }

    const handleCreatedSuccess = () => {
        setCreateOpen(true)
    }

    const handleEditSuccess = () => {
        setEditOpen(true)
    }


    const handleOpenCreateCustomer = () => {
        setIsCreateDialogOpen(true)
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const onDeleteError = (data) => {
        sendAlert(data?.response?.data?.message, "ERROR")
    }
    const onDeleteSuccess = (data) => {
        sendAlert(data?.message, "SUCCESS")
    }
    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer("listPurchaseProduct", initialData)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [message, setMessage] = useState('')
    const [purchaseId, setPurchaseId] = useState('')

    const handleRoomDelete = (purchase_id) => {
        // setSingleData(handleGetData?.data?.data?.find(x => x.purchase_id === purchase_id))
        setOpenDeleteModal(true)
        setPurchaseId(purchase_id)
    }

    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false)
        setIsOpenEditDialog(false)
    }

    const handleOpenEditModal = (id) => {
        setSingleData(handleGetData.data.data.find(x => x.purchase_id === id))
        setIsOpenEditDialog(true)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setCreateOpen(false)
        setEditOpen(false)
    }
    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            [e.target.name]: value
        }
        deBounce(setGetListParams, data)
        // setGetListParams(data)
    }

    const { reportDownload } = useDownloadFile()
    const handleDownloadReport = (e, purchase_id, roomData, idx) => {
        e.preventDefault()
        let data = {
            product_id: roomData.product_id,
            purchase_id: purchase_id
        }
        reportDownload.mutate(data)
    }

    const handleOpenQrModal = (id) => {
        setBillData(handleGetData.data.data.find(x => x.purchase_id === id))
        setOpenBillModal(true)
    }



    return (<Layout>
        <div className="py-4 border-t border-gray-300">
            <div>
                <h1 className='text-center heading capitalize text-3xl'>Billing</h1>
            </div>
            <div className="flex items-center space-x-4">
                <div className="flex-1">
                    <input type="text" autocomplete="off" className="input2" name="searchQuery" placeholder="Search by product name..." value={paramsObject.search_query} onChange={handleSearch}></input>
                </div>
                <button className="btn3 text-white" onClick={handleOpenCreateCustomer}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    <span className="text-sm">Create Purchase Product</span>
                </button>
            </div>
        </div>
        {handleGetData.isLoading ? <Spinner />
            : handleGetData.isError ? <Errors errorObject={handleGetData?.error} inline />
                : handleGetData?.data?.data <= 0 ? <div className="flex items-center justify-center h-96">
                    <p className="text-lg font-semibold">No Data Available</p>
                </div> : <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead>
                            <tr className="text-left">
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Sr.no
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Product Name
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Vendor Name
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Quantity
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Date
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Bill
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {handleGetData?.data.data.map((roomData, idx) => {
                                const { product_name, vendor_name, quantity, date, media_path, purchase_id, qr_process_status, active, qrcode_generated } = roomData
                                return <tr key={idx}>
                                    <td className="border-dashed border-t border-gray-200 userId">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                            {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{product_name}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{vendor_name}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{quantity}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{getDateFromTimestamp(date)}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm cursor-pointer">
                                            <InsertDriveFileIcon onClick={() => handleOpenQrModal(purchase_id)} />
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <div className='w-full flex items-center justify-start'>
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                                                <span data-id={purchase_id}><EditIcon onClick={() => handleOpenEditModal(purchase_id, roomData)} /></span>
                                            </span>
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                                                <span data-id={purchase_id}><DeleteIcon onClick={() => handleRoomDelete(purchase_id)} /></span>
                                            </span>
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4">
                                                {checkIfNotEmpty(media_path) ?
                                                    <a className="bg-[color:var(--primaryRed)] text-white w-36 h-10 rounded-lg cursor-pointer" href={media_path} target="blank" download>
                                                        <span className='flex justify-center mt-2'><FileDownloadIcon /> Download QR</span>
                                                    </a>
                                                    : <span className='cursor-auto'>{qr_process_status}</span>}
                                            </span>


                                            {/* {reportDownload.isLoading ? <div className='w-12 h-12'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : qrcode_generated == 'Yes' ?
                                                <button onClick={(e) => handleDownloadReport(e, purchase_id, roomData, idx)} data-id={purchase_id}
                                                    className="bg-[color:var(--primaryRed)] text-white w-36 h-10 rounded-lg">
                                                    <FileDownloadIcon /> Download Qr
                                                </button> : <button disabled
                                                    className="disabled:opacity-75 bg-[color:var(--primaryRed)] text-white w-36 h-10 rounded-lg">
                                                    <FileDownloadIcon /> Download Qr
                                                </button>} */}


                                        </div>
                                    </td>
                                </tr>
                            })}

                        </tbody>
                    </table>
                </div>
                    <div className=" my-7">
                        <Pagination currentPage={paramsObject.pageNo} pageCount={handleGetData?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick} />
                    </div>
                </>
        }
        <CreatePurchaseProduct open={isCreatDialogOpen} onClose={handleCloseDialog} onCreate={handleCreatedSuccess} />
        {isOpenEditDialog && <EditPurchaseProduct onEdit={handleEditSuccess} singleData={singleData} open={isOpenEditDialog} onClose={() => setIsOpenEditDialog(false)} />}
        {openDeleteModal && <DeleteItem onDelete={onDeleteSuccess} onError={onDeleteError} open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} id={purchaseId} url={"/deletePurchaseProduct"} queryKey={"listPurchaseProduct"} />}
        {openBillModal && <EditBillModal onEdit={handleEditSuccess} billData={billData} open={openBillModal} onClose={() => setOpenBillModal(false)} />}


    </Layout >);
}

export default PurchaseMaster;