import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/layout";
import Spinner from "../../components/spinner";
import useGetDataFromServer from "../../hooks/useGetDataFromServer";
import { checkIfEmpty, getDateFromTimestamp } from "../../utility";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteItem from "../../components/DeleteItem/DeleteItem";
import AlertContext from "../../context/alerts";
import Pagination from "../../components/pagination";
import deBounce from "../../hooks/useDebounce";

function DeductProduct() {
    const { id } = useParams();
    const { sendAlert } = useContext(AlertContext)
    const initialData = {
        pageNo: 0,
        limit: 20,
        searchQuery: '',
        id: id
    }
    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer('getProductDetails ', initialData)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [singleData, setSingleData] = useState({})
    const [deleteQr, setDeleteQr] = useState([])

    const handleDelete = (e, qr_code) => {
        e.preventDefault()
        let data = {
            qr_code: qr_code,
            date: new Date().toDateString()
        }
        setSingleData(data)
        setOpenDeleteModal(true)
    }
    const onDeleteSuccess = (data) => {
        sendAlert(data?.message, "SUCCESS")
    }

    const onDeleteError = (data) => {
        sendAlert(data?.response?.data?.message, "ERROR")
    }
    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            [e.target.name]: value
        }
        deBounce(setGetListParams, data)
        // setGetListParams(data)
    }

    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }
    return (<Layout>
        <div className="mt-5">
            <input type="text" autocomplete="off" className="input2" name="searchQuery" placeholder="Search Product..." value={paramsObject.search_query} onChange={handleSearch}></input>
        </div>
        {handleGetData.isLoading ? <div className='flex justify-center'><Spinner /></div> : handleGetData?.data?.productDetails <= 0 ? <div className="flex items-center justify-center h-96">
            <p className="text-lg font-semibold">No Data Available</p>
        </div> :
            <>

                <div className="grid grid-cols-2 px-5">
                    {handleGetData?.data?.productDetails?.map((productData, idx) => {
                        return (
                            <div className="relative h-52 w-[590px] bg-white shadow-lg border-2 border-[#0084b5] rounded px-2 mt-9">
                                <div className="grid grid-cols-2 mt-5 px-5">
                                    <div className="space-y-6">
                                        <div className="space-y-1.5 flex space-x-2">
                                            <p className="font-bold text-lg mt-1">QR CODE : </p>
                                            <p className="text-base font-medium ">{productData.qr_code}</p>
                                        </div>
                                        <div className="space-y-1.5 flex space-x-2">
                                            <p className="font-bold text-lg mt-1">Amount : </p>
                                            <p className="text-base font-medium ">{productData.perpiece_price}</p>
                                        </div>
                                    </div>
                                    <div className="space-y-6">
                                        <div className="space-y-1.5 flex space-x-2">
                                            <p className="font-bold text-lg mt-1">Mapped to : </p>
                                            <p className="text-base font-medium ">{checkIfEmpty(productData.room_name) ? "" : productData.room_name}</p>
                                        </div>
                                        <div className="space-y-1.5 flex space-x-2">
                                            <p className="font-bold text-lg mt-1">Date : </p>
                                            <p className="text-base font-medium ">{getDateFromTimestamp(productData.purchase_date)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    {productData.status === 'Mapped' ? <p className="absolute left-3 bottom-3 text-xl rounded border p-1.5 bg-[#0080000d] text-[#008000]">{productData.status}</p>
                                        : productData.status === 'Unmapped' ? <p className="absolute left-3 bottom-3 text-xl rounded border-[#FF0000] p-1.5 bg-[#FF7C60] text-black">{productData.status}</p>
                                            : productData.status === 'Deleted' ? <p className="absolute left-3 bottom-3 text-xl rounded border-[#FF0000] p-1.5 bg-[#FF7C60] text-black">{productData.status}</p>
                                                : ""}

                                </div>
                                <div>
                                    {productData.status === 'deleted' ? "" : <button
                                        className="absolute bottom-3 right-3">
                                        <DeleteIcon onClick={(e) => handleDelete(e, productData.qr_code)} />
                                    </button>}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className=" my-9 ml-[1rem]">
                    <Pagination currentPage={paramsObject.pageNo} pageCount={handleGetData?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick} />
                </div>
            </>
        }

        {openDeleteModal && <DeleteItem onDelete={onDeleteSuccess} onError={onDeleteError} open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} multiParam={singleData} url={"/productDeduct"} queryKey={"getProductDeductionList"} />}
    </Layout>);
}

export default DeductProduct;