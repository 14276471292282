import React, { useState, useEffect } from "react";
import { Dialog, Slide } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import usePostDataToServer from "../../hooks/usePostDataToServer";
import CloseIcon from "@mui/icons-material/Close";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import RemarkModal from "./RemarkModal";
import { useParams } from "react-router-dom";
import { checkIfEmpty, checkIfNotEmpty } from "../../utility";
import Spinner from "../../components/spinner";
import AlertContext from "../../context/alerts";
import { useContext } from "react";

function RoomCategoryModal({ onClose, open, modalData, data, yearId, apiData, yearData }) {
  const { roomname, roomid } = useParams();
  const { sendAlert } = useContext(AlertContext);
  const [changeData, setChangeData] = useState();

  const getSubmitAudit = () => {
    return {
      floor_room_id: roomid,
      remarks: "",
      initial_quantity: "",
      final_quantity: "",
      year_id: yearId?.year_id,
      product_id: data?.data?.data[modalData]?.product_id,
      stats_id: data?.data?.data[modalData]?.stats_id,
    };
  };

  useEffect(() => {
    if (
      checkIfNotEmpty(yearId?.year_id) &&
      checkIfNotEmpty(data?.data?.data[modalData])
    ) {
      setNewData(getSubmitAudit());
    }
  }, [yearId?.year_id, data?.data?.data[modalData]]);

  const [isRemarkOpen, setIsRemarkOpen] = useState(false);
  const [auditData, setAuditData] = useState(getSubmitAudit());
  const [newData, setNewData] = useState(getSubmitAudit());


  const { setDataToServer, error } = usePostDataToServer("/updateStats", "", {
    onSuccessCb: (data) => {
      sendAlert(data.message, "SUCCESS");
    },
    onErrorCb: (err) => {
      // console.log("error", err);
      sendAlert(err?.response?.data?.message, "ERROR")
    },
  });

  const openRemark = (e) => {
    e.preventDefault();
    setIsRemarkOpen(true);
  };

  const handleClearAndCloseForm = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleInputs = (e) => {
    let value = e.target.value;
    let data = {
      ...newData,
      [e.target.name]: value,
    };
    setNewData(data);
  };

  const buttonSubmitAudit = (e, category) => {
    e.preventDefault();
    let data = { ...newData };
    data.initial_quantity = category?.current_quantity;
    data.product_id = category.product_id;
    data.stats_id = category.stats_id;
    setNewData(data);
    setDataToServer.mutate(data);
    setChangeData(category);
    if (category?.final_quantity == "0") {
      data.final_quantity = category?.initial_quantity;
      setNewData(data);
    }
  };
  return (
    <Dialog PaperProps={{ sx: { top: "-23%" } }} maxWidth={"lg"} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
      <div className="bg-white w-full p-5">
        <div className="modal-head flex items-center justify-between">
          <div className="mt-1.5">
            <h1 className="heading capitalize text-3xl">Categories</h1>
          </div>
          <div>
            <div onClick={onClose}>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <form>
          <>
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
              <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead>
                  <tr className="text-left">
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Sr.no
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Product Name
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Initial value
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      End value
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Current Value
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Remarks
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.data[modalData]?.map((category, idx) => {
                    // console.log('category :>> ', category);
                    return (
                      <tr key={idx}>
                        <td className="border-dashed border-t border-gray-200">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm ">
                            {idx + 1}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 ">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {category?.product_name}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            <input disabled value={category?.initial_quantity} type="text" className="border-2 border-slate-600 h-10 w-24 px-10" />
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            <input
                              disabled
                              value={category?.final_quantity}
                              type="text"
                              className="border-2 border-slate-600 h-10 w-24 px-10"
                            />
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            <input
                              disabled
                              value={category?.current_quantity}
                              type="text"
                              className="border-2 border-slate-600 h-10 w-24 px-10"
                            />
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            <button onClick={(e) => openRemark(e)}>
                              <TextSnippetIcon />
                            </button>
                          </span>
                        </td>
                        {setDataToServer.isLoading ?
                          <Spinner /> : <td className="border-dashed border-t border-gray-200">
                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                              {category?.initial_quantity === "0" ||
                                checkIfEmpty(category?.initial_quantity) ? (
                                <button
                                  onClick={(e) => buttonSubmitAudit(e, category)}
                                  className="rounded-full w-28 h-7 font-normal text-white bg-[color:var(--primaryRed)]">
                                  Submit Audit
                                </button>
                              ) : (
                                <button
                                  onClick={(e) => buttonSubmitAudit(e, category)}
                                  className="rounded-full w-28 h-7 font-normal text-white bg-[color:var(--primaryRed)]"
                                >
                                  End Audit
                                </button>
                              )}
                            </span>
                          </td>}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        </form>
      </div>
      {setIsRemarkOpen && (
        <RemarkModal
          handleInputs={handleInputs}
          setAuditData={setAuditData}
          auditData={auditData}
          open={isRemarkOpen}
          onClose={() => setIsRemarkOpen(false)}
        />
      )}
    </Dialog>
  );
}

export default RoomCategoryModal;
