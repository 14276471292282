import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import Cookies from "universal-cookie";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import user from '../../assets/images/user.svg'

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  let cookies = new Cookies
  let name = cookies.get('name')

  return (
    <div className="navbar">
      {/* <div className="w-14 h-14">
        <img src={logo2} />
      </div> */}
      <p className="p">Bai Avabai F. Petit Girls High School</p>
      <div className="wrapper">
        <div className="items">
          <div className="item">
            <p>Welcome <span className="font-extrabold">{name}</span></p>
            <img
              alt=""
              src={user}
              className="avatar"
            />
            {/* <div className="avatar">
              <PersonOutlineIcon />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
