import React, { useState, useEffect, useRef } from 'react';
import "./AddRooms.scss";
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import { AsyncPaginate } from 'react-select-async-paginate';
import { checkIfNotEmpty } from '../../utility';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import { displayRoomDetails } from '../../apis/roomslistapi';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function AddRooms({ setInfrastructureData, infrastructureData, open, onClose, handleAddWingForm, currentFloorId, addRooms, wingId, currentWingId }) {
    const initialData = {
        roomName: '',
        roomType: ''
    }
    const [roomsData, setRoomsData] = useState(initialData);
    const [roomDropName, setRoomName] = useState([])
    const [pageNoBuilding, setPageNoBuilding] = useState(0);
    const initialLoadBuilding = useRef(false)
    const [previousState, setPreviousState] = useState("");
    const [newState, setNewState] = useState([])

    const formatData = (arr, valueName, labelName) => {
        let newarr = []
        for (let i = 0; i < arr?.length; i++) {
            newarr.push({ value: arr[i]?.[valueName], label: arr[i]?.[labelName], ...arr[i] })
        }
        return newarr;
    }

    const getBuildingName = async () => {
        const res = await displayRoomDetails();
        const res2 = formatData(res.data.data, 'id', 'type_name')
        setRoomName(res2);
    }

    useEffect(() => { getBuildingName() }, [])

    useEffect(() => {
        if (open) {
            setPageNoBuilding(0)
        }
    }, [open])



    // const { handleGetData, paramsObject } = useGetDataFromServer("listingRoomType", { searchQuery: '', pageNo: '' }, "", {
    //     onSuccessCb: (data) => {
    //         const res2 = formatData(data?.data, 'id', 'type_name')
    //         setRoomName(res2);
    //     },
    //     onErrorCb: (err) => {
    //         console.log('error', err)
    //     }
    // })

    // const { setDataToServer } = usePostDataToServer("/listingRoomType", "", {
    //     onSuccessCb: (data) => {
    //         const res2 = formatData(data?.data, 'id', 'type_name')
    //         setRoomName(res2);
    //         setNewState(data?.data)
    //     },
    //     onErrorCb: (err) => {
    //     }
    // })

    const handleInputs = (e) => {
        let value = e.target.value
        let data = {
            ...roomsData,
            [e.target.name]: value
        }
        setRoomsData(data)
    }

    window.onkeydown = function (e) {
        if (e.keyCode === 27) { // Key code for ESC key
            e.preventDefault();
            return;
        }
    };

    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        addRooms(currentFloorId, roomsData.roomName, roomsData.roomType, currentWingId)
        onClose()
    }


    const loadBuildingNames = (search, prevOptions) => {
        const formatDataFn = (resp) => {
            return formatData(resp, 'id', 'type_name')
        }
        return loadOptions(search, prevOptions,
            initialLoadBuilding,
            roomDropName, setRoomName,
            pageNoBuilding, setPageNoBuilding, formatDataFn)
    }

    const loadOptions = async (search, prevOptions, initialLoad, state, setState, pageNo, setPageNo, formatDataFn) => {
        try {
            if (!initialLoad.current && !search) {
                initialLoad.current = true;
                return {
                    options: state,
                    hasMore: true
                };
            }
            else {
                let newPage;
                if (checkIfNotEmpty(search) && previousState !== search) {
                    newPage = 0;
                    setPageNo(newPage);
                } else {
                    newPage = pageNo;
                    // newPage = pageNo + 1;
                    // setPageNo(newPage);
                }
                let res = await displayRoomDetails(newPage, search)
                newPage = pageNo + 1;
                setPageNo(newPage);
                const data = state.concat(res?.data?.data)
                setState(data)
                let temps = res.data.data;

                let hasMore = true;
                if (res?.data?.data?.length == 0) {
                    hasMore = false;
                }
                let arr = formatDataFn(res?.data?.data);
                setPreviousState(search)

                return {
                    options: arr,
                    hasMore: hasMore
                };
            }
        }
        catch (err) {
            // console.log(err)
        }
    }
    const handleBuildingName = (e) => {
        let data = {
            ...roomsData,
            roomType: e.id
        }
        setRoomsData(data)
    }

    return (<Dialog fullScreen fullWidth={true} open={open} onClose={(e) => onClose(e)} TransitionComponent={Transition}>
        <div className='container_xxl'>
            <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                <div>
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div>
                    <h1 className='heading capitalize'>Add Rooms</h1>
                </div>
            </div>
            <form className='mt-20' onSubmit={(e) => handleClearAndCloseForm(e)}>
                <div className='flex justify-center mt-5 space-x-48'>
                    <input required className='w-80 h-12 border-2 border-grey px-2 rounded-md'
                        name='roomName'
                        placeholder='Room Name'
                        onChange={handleInputs}>
                    </input>
                    <div className='w-80'>
                        {/* <select required onChange={handleInputs} name="roomType"
                            className="border-2 border-grey w-80 h-12 rounded-md">
                            <option value="">--Select Type--</option>
                            {handleGetData?.data?.data?.map((roomDrop, id) => { return <option value={roomDrop?.id}>{roomDrop?.type_name}</option> })}
                        </select> */}

                        <AsyncPaginate
                            value={roomDropName && roomDropName?.id}
                            // options={buildingName}
                            isSearchable={true}
                            lab
                            loadOptions={loadBuildingNames}
                            onChange={(e) => handleBuildingName(e)}
                        />
                    </div>
                </div>
                <div className="flex justify-center mt-5">
                    <button type='submit' className='btn3 text-white'>Add Room</button>
                </div>
            </form>
        </div>
    </Dialog>);
}

export default AddRooms;