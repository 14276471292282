import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from 'react'
import axios from "../apis/axios";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../config";
import { checkIfEmpty } from "../utility";
const cookies = new Cookies();


function useGetDataFromServer(url, initialData, enabled, cbObj) {
  const [paramsObject, setGetListParams] = useState(initialData)
  let onSuccessCb = cbObj?.onSuccessCb
  let onErrorCb = cbObj?.onErrorCb
  const [error, onError] = useState()
  const [success, onSuccess] = useState()

  const handleGetData = useQuery([url, paramsObject], () => getUsers(paramsObject), {
    refetchOnWindowFocus: false,
    enabled: checkIfEmpty(enabled) ? true : enabled,
    onError: (err) => {
      onError(true)
      if (onErrorCb instanceof Function) {
        onErrorCb(err)
      }
    },
    onSuccess: (data) => {
      onSuccess(true)
      if (onSuccessCb instanceof Function) {
        onSuccessCb(data)
      }
    }
  })
  const getUsers = async (paramsObject) => {
    let TOKEN = cookies.get(TOKEN_NAME);
    let fd = new FormData()
    for (var key in paramsObject) {
      fd.append(key, paramsObject[key]);
    }
    const response = await axios({
      method: "POST",
      data: fd,
      url: `/${url}`,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data
  }
  return { paramsObject, setGetListParams, handleGetData, error, onError, success, onSuccess }

}

export default useGetDataFromServer