import React from 'react';
import { Dialog, Slide } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const IS_REQUIRED = false

function AddAssembledProduct({ open, onClose }) {
    const initialData = {
        product_name: '',
        product_image: '',
        product_component: [
            {
                component_name: '',
                component_quantity: ''
            },
        ]
    }
    const [productFormData, setProductFormData] = useState(initialData)
    const handleCustomerInputs = (e) => {
        let value = e.target.value
        let data = {
            ...productFormData,
            [e.target.name]: value
        }
        setProductFormData(data)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        // console.log(productFormData);
    }

    const handleClearAndCloseForm = () => {
        setProductFormData(initialData)
        onClose()
    }

    const handleAddProductComponent = () => {
        let array = productFormData['product_component']
        array.push({
            component_name: '',
            component_quantity: ''
        })
        setProductFormData({ ...productFormData })
    }

    const handleDeleteComponent = (e) => {
        e.preventDefault();
        let element = e.currentTarget
        let deleteId = element.getAttribute('data-deleteid');
        let array = productFormData['product_component']
        array.splice(deleteId,1)
        setProductFormData({ ...productFormData })
    }
    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={handleClearAndCloseForm} TransitionComponent={Transition}>
            <div className='container_xxl'>
                <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={handleClearAndCloseForm}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading capitalize'>Add Assembled Product</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    <form className='' autoComplete='off' onSubmit={handleSubmitForm}>
                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <label htmlFor="Product Name" className='label'>Product Name</label>
                                <input type="text" required={IS_REQUIRED} value={productFormData.product_name} onChange={handleCustomerInputs} placeholder='Enter Product Name' name='product_name' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Upload Product Image" className='label'>Upload Product Image</label>
                                <input type="file" required={IS_REQUIRED} value={productFormData.name} onChange={handleCustomerInputs} name='product_image' className='input' />
                            </div>
                        </div>
                        <div className='flex items-center justify-between my-4'>
                            <label htmlFor="Product Name" className='label'>Product Components</label>
                            <button onClick={handleAddProductComponent} type='button' className='flex items-center justify-center btn3'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                <span>Add Component</span>
                            </button>
                        </div>
                        <div className=' space-y-4'>
                            {productFormData?.product_component?.map((component, idx) => {
                                const { component_name, component_quantity } = component
                                return <div className='grid grid-cols-2 gap-4' key={idx}>
                                    <div>
                                        <select name="state" required={IS_REQUIRED} className='input' value={component_name} onChange={handleCustomerInputs}>
                                            <option value="">--Select Product--</option>
                                            <option value="karanataka">Karanataka</option>
                                        </select>
                                    </div>
                                    <div>
                                        <div className='flex items-center space-x-4'>
                                            <input type="text flex-1" required={IS_REQUIRED} value={component_quantity} onChange={handleCustomerInputs} placeholder='Enter Product Quantity' name='product_quantity' className='input' />
                                            {idx > 0 && 
                                            <div data-deleteid={idx} className=' cursor-pointer w-6 h-6' onClick={handleDeleteComponent} >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                </svg>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className='text-center mt-6'>
                            <button className="btn3 mx-auto"><span>Save</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default AddAssembledProduct