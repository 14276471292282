import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../apis/axios";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../config";
const cookies = new Cookies();



const useDeleteDataFromServer = (url, refreshUrl, { onSuccesCB, onErrorCB }) => {
    const queryClient = useQueryClient()
    const setDeleteData = useMutation((data) => deleteUser(data, url), {
        onError: (err) => {
            onErrorCB(err)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(refreshUrl)
            onSuccesCB(data)
        }
    })

    return { setDeleteData }
}

const deleteUser = async (getFormData, url) => {
    let TOKEN = cookies.get(TOKEN_NAME);
    let fd = new FormData()
    for (var key in getFormData) {
        fd.append(key, getFormData[key]);
    }
    const response = await axios({
        method: "POST",
        data: fd,
        url: url,
        headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data
}

export { useDeleteDataFromServer, deleteUser }