import React, { useState, useEffect, useContext } from 'react';
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import FormElement from '../../components/form/FormElement';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../components/spinner';
import AlertContext from '../../context/alerts';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditCategory({ singleData, open, onClose, onEdit }) {

    const { sendAlert } = useContext(AlertContext)
    const [isChanged, setIsChanged] = useState(false)


    const [categoryTypeData, setCategoryTypeData] = useState(singleData);
    const { setDataToServer, error } = usePostDataToServer("/updateCategory", "listCategory", {
        onSuccessCb: (data) => {
            onEdit()
            onClose()
        },
        onErrorCb: (err) => {
            // console.log('error', err)
            sendAlert(err?.response?.data?.message, "ERROR")
        }
    })

    const handleUpdateInputs = (e) => {
        let value = e.target.value
        let data = {
            ...categoryTypeData,
            [e.target.name]: value
        }
        setCategoryTypeData(data)
        setIsChanged(true)
    }
    const handleSubmitForm = (e) => {
        e.preventDefault()
        let data = {
            category_name: categoryTypeData.category_name,
            id: singleData.id
        }
        setDataToServer.mutate(data)
    }

    const handleClearAndCloseForm = (e) => {
        e.preventDefault()
        // setRoomTypeData(singleData)
        onClose()
    }

    return (<Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={(e) => handleClearAndCloseForm(e)}>
        <div className='bg-white w-full px-5'>
            <div className='modal-head flex items-center justify-between'>
                <div className='mt-1.5'>
                    <h1 className='heading capitalize text-2xl'>Edit Category Type</h1>
                </div>
                <div>
                    <div onClick={onClose}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmitForm} className="py-7">
                <div className='flex justify-center mt-5 space-x-48'>
                    <FormElement
                        label={'Category Type'}
                        onChange={(e) => handleUpdateInputs(e)}
                        placeholder={'e.g. Furniture'}
                        autocomplete={"off"}
                        element={'input'}
                        name={'category_name'}
                        required={'required'}
                        value={categoryTypeData?.category_name}
                        errorMessage={'This is a Required Field'} />
                </div>
                {setDataToServer.isLoading ?
                    <Spinner /> : isChanged == true ?
                        <div className="flex justify-center mt-5">
                            <button type='submit' className='btn3 text-white'>Edit</button>
                        </div> : <div className="flex justify-center mt-5">
                            <button disabled type='submit' className='btn3 text-white bg-blue-300'>Edit</button>
                        </div>}
            </form>
            {error && <small className="text-red-700 font-semibold mt-1">{setDataToServer?.error?.response?.data?.message}</small>}
        </div>
    </Dialog>
    )

}
export default EditCategory;