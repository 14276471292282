import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './tabnav.css'

function TabNav({navData}) {
  const { pathname } = useLocation()
  return (
    <>
      <div className='w-full h-full'>
        <ul className='flex items-center justify-center space-x-4'>
          {navData?.map((navData, idx) => {
            return <li key={idx} className='tabNav_items'>
              <Link className={`px-8 py-2 ${pathname.substring(1) === navData.slug && 'active'}`} to={`/${navData.slug}`}>
                <span className='mr-2'>
                  {navData.icon}
                </span>
                {navData.title}
              </Link>
            </li>
          })}
        </ul>
      </div>
    </>
  )
}

export default TabNav