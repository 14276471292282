import React, { useContext, useState } from 'react'
import { Dialog, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AddRooms from '../../components/AddRooms/AddRooms';
import { checkIfEmpty, checkIfNotEmpty } from '../../utility';
import usePostDataToServer from '../../hooks/usePostDataToServer';
import useGetDataFromServer from '../../hooks/useGetDataFromServer';
import { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Spinner from '../../components/spinner';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RoomUpdate from '../../components/RoomUpdate/RoomUpdate';
import AlertContext from "../../context/alerts";
import FormElement from '../../components/form/FormElement';
import DeleteItem from '../../components/DeleteItem/DeleteItem';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UpdateInfrastructure({ open, onClose, buildingId }) {
    let updateId = {
        buildingId: buildingId
    }
    const { sendAlert } = useContext(AlertContext)
    const [infrastructureData, setInfrastructureData] = useState();
    const getSingleData = usePostDataToServer("/listingInfrastructure", "",
        {
            onSuccessCb: (data) => {
                // console.log('data :>> ', data);
                setInfrastructureData({ ...data })
            },
            onErrorCb: (err) => {
                // console.log('err :>> ', err);
                sendAlert(err?.response?.data?.message, "ERROR")

            }
        })

    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [currentFloorId, setCurrentFloorId] = useState("")
    const [currentWingId, setCurrentWingId] = useState("")
    const [expanded, setExpanded] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openDeleteWingModal, setOpenDeleteWingModal] = useState(false)
    const [building, setBuilding] = useState([]);
    const [wing, setWing] = useState([]);
    const [deteteParams, setDeleteParams] = useState({})

    const handleWingDelete = (e, id) => {
        e.preventDefault()
        let data = {
            screen_name: 'WING',
            id: id
        }
        setDeleteParams(data)
        setOpenDeleteModal(true)
    }
    const handleFloorDelete = (e, id) => {
        e.preventDefault()
        let data = {
            screen_name: 'FLOOR',
            id: id
        }
        setDeleteParams(data)
        setOpenDeleteModal(true)
    }
    const handleRoomDelete = (e, id) => {
        e.preventDefault()
        let data = {
            screen_name: 'ROOM',
            id: id
        }
        setDeleteParams(data)
        setOpenDeleteModal(true)
    }

    const updateFloorMaster = usePostDataToServer("/updateFloorMaster", {
        onSuccessCb: (data) => {
            // console.log('data.message :>> ', data.message);
            sendAlert(data.message, "SUCCESS")
        },
        onErrorCb: (err) => {
            sendAlert(err.data.message, "ERROR")
        }
    })
    const updateBuildingMaster = usePostDataToServer("/updateBuildingMaster", {
        onSuccessCb: (data) => {
            sendAlert(data.message, "SUCCESS")
        },
        onErrorCb: (err) => {
            sendAlert(err.data.message, "ERROR")
        }
    })
    const updateWingMaster = usePostDataToServer("/updateWingMaster", {
        onSuccessCb: (data) => {
            sendAlert(data.message, "SUCCESS")
        },
        onErrorCb: (err) => {
            sendAlert(err.data.message, "ERROR")
        }
    })
    const updateRoomMaster = usePostDataToServer("/updateRoomMaster", "", {
        onSuccessCb: (data) => {
            sendAlert(data.message, "SUCCESS")
        },
        onErrorCb: (err) => {
            sendAlert(err.data.message, "ERROR")
        }
    })
    const addInfrastructure = usePostDataToServer("/addInfrastructure", "", {
        onSuccessCb: (data) => {
            sendAlert(data.message, "SUCCESS")
            getSingleData?.setDataToServer?.mutate(updateId)

        },
        onErrorCb: (err) => {
            // console.log('err :>> ', err);
            sendAlert(err.data.message)
        }
    })


    const handleRecallData = () => {
        getSingleData?.setDataToServer?.mutate(updateId)
        return;
    }

    const handleRoomUpdate = (roomId, floorId, roomUpdate) => {
        let data = {
            roomName: roomUpdate?.roomName,
            roomId: roomUpdate?.roomId,
            floorId: floorId,
            room_type_id: roomUpdate.roomType
        }
        updateRoomMaster.setDataToServer.mutate(data)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    }
    const handleClearAndCloseForm = () => {
        onClose()
    }

    const handleOpenAddRooms = (floorId, wingId) => {
        setCurrentFloorId(floorId)
        setCurrentWingId(wingId)
        setIsCreateDialogOpen(true)
    }
    const [newWing, setNewWing] = useState([])
    const handleAddWingForm = (e) => {
        e.preventDefault()
        let obj = {
            wingName: "",
            floor: [],
            levelCount: ''
        }
        const newobj = { ...infrastructureData }
        //newobj.wing.push(obj)
        let data = newWing.slice()
        data.push(obj)
        setNewWing(data)
        updateWing(e, newobj, obj)
    }

    const handleBuildingUpdate = (e, newobj) => {
        e.preventDefault()
        let data = {
            buildingName: newobj?.buildingName,
            buildingId: newobj?.buildingId,
        }
        updateBuildingMaster.setDataToServer.mutate(data)
    }

    const handleInputs = (e) => {
        let value = e.target.value
        let newobj = { ...infrastructureData }
        newobj.buildingName = value
        setInfrastructureData(newobj)
        handleBuildingUpdate(e, newobj)
    }
    // const handleWingDelete = (wingId) => {
    //     const newobj = { ...infrastructureData }
    //     newobj.wing.splice(wingId, 1)
    //     setInfrastructureData(newobj);

    //     // const data = newWing.slice()
    //     // let newarr = data.splice(wingId, 1)
    //     // setNewWing(newarr)
    // }
    // const handleFloorDelete = (wingId, floorId) => {
    //     let wingData = { ...infrastructureData }
    //     wingData.wing[wingId].floor.splice(floorId, 1)
    //     setInfrastructureData(wingData);
    // }

    const handleWingName = (e, wingId) => {
        let value = e.target.value;
        let newObj = { ...infrastructureData }
        newObj.wing[wingId].wingName = value;
        setInfrastructureData(newObj);
        handleWingNameUpdate(e, wingId, newObj)
    }

    const [newFloor, setNewFloor] = useState([])
    const handleFloorName = (e, wingId, floorId, floorData) => {
        let value = e.target.value;
        let newObj = { ...infrastructureData }
        newObj.wing[wingId].floor[floorId].floorName = value;
        setInfrastructureData(newObj);
        handleFloorUpdate(e, wingId, floorId, floorData, newObj)

        const data = newFloor.slice()
        // console.log('data.wing :>> ', data.wing);
        data.wing[wingId].floor[floorId].floorName = value;
        data.push(newObj)
        setNewFloor(data)

    }
    const handleFloorInput = (e, id) => {
        let value = e.target.value;
        let newObj = { ...infrastructureData }
        newObj.wing[id].levelCount = value;
        setInfrastructureData(newObj);
    }

    const generateFloorField = (e, id, wingId) => {
        e.preventDefault()
        let floorarr = [];
        let data = { ...infrastructureData }
        let offset = data.wing[id].floor.length;
        if (checkIfEmpty(data.wing[id].levelCount)) {
            sendAlert("Floor input cannot be empty", "WARNING")
            return;
        }
        for (let i = 0; i < data.wing[id].levelCount; i++) {
            let obj = {
                floorName: 'Floor ' + (offset + i + 1), rooms: []
            }
            floorarr.push(obj)
        }
        data.wing[id].floor = data.wing[id].floor.concat(floorarr);
        //setInfrastructureData(data)
        setNewFloor(floorarr)
        updateFloor(e, wingId, floorarr)
    }

    const [newRooms, setNewRooms] = useState([])
    const addRooms = (e, floorId, roomName, roomType, wingId) => {
        let copy = newRooms.slice()
        let newObj = { ...infrastructureData }
        if (checkIfEmpty(copy[wingId])) {
            copy[wingId] = []
        }
        if (checkIfEmpty(copy[wingId][floorId])) {
            copy[wingId][floorId] = []
        }
        copy[wingId][floorId].push({
            roomName: '',
            roomType: ''
        })
        //setNewRooms(copy)
        updateRooms(e, wingId, floorId, "", "", copy, newObj)
    }

    // const handleRoomDelete = (wingId, floorId, roomId) => {
    //     let data = { ...infrastructureData }
    //     data.wing[wingId].floor[floorId].rooms.splice(roomId, 1)
    //     setInfrastructureData(data);
    // }
    const handleNewRoomDelete = (floorId, roomId, wingId) => {
        let copy = newRooms.slice()
        copy[wingId][floorId].splice(roomId, 1)
        setNewRooms(copy)
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        getSingleData?.setDataToServer?.mutate(updateId)
    }, [])

    // const handleRoomInputs = (e, wingId, floorId, roomId) => {
    //     let value = e.target.value;
    //     let data = { ...infrastructureData }
    //     if (e.target.name === "roomName") {
    //         data.wing[wingId].floor[floorId].rooms[roomId].roomName = value
    //     }
    //     data.wing[wingId].floor[floorId].rooms[roomId].roomType = value
    //     setInfrastructureData(data)
    // }

    // const { handleGetData, paramsObject } = useGetDataFromServer("listingRoomType")
    // const [roomUpdate, setRoomUpdate] = useState('')
    // const handleRoomInputs = (e, wingId, floorId, roomId) => {
    //     let value = e.target.value;
    //     let data = { ...roomUpdate }
    //     if (e.target.name === "roomName") {
    //         data.roomName = value
    //     }
    //     data.roomType = value
    //     setRoomUpdate(data)
    // }

    const changeAddRoomDetails = (e, idx, wingId, floorId) => {
        e.preventDefault()
        let copy = newRooms.slice()
        copy[wingId][floorId][idx][e.target.name] = e.target.value
        setNewRooms(copy)
    }


    const handleWingNameUpdate = (e, wingId, newObj) => {
        e.preventDefault()
        // let newObj = { ...infrastructureData }
        let data = {
            wingName: newObj.wing[wingId].wingName,
            wingId: newObj.wing[wingId].wingId,
        }
        updateWingMaster.setDataToServer.mutate(data)
    }

    const handleFloorUpdate = (e, wingId, floorId, floorData, newObj) => {
        e.preventDefault()
        // let newObj = { ...infrastructureData }
        let data = {
            floorName: floorData?.floorName,
            wingId: newObj.wing[wingId].wingId,
            floorId: newObj.wing[wingId].floor[floorId].floorId,
        }
        updateFloorMaster.setDataToServer.mutate(data)
    }

    const updateRooms = (e, wingId, floorId, floorIdx, windId, copy, newObj) => {
        e.preventDefault()
        let data = {
            screen_name: 'ROOM',
            floorId: floorId,
            roomData: JSON.stringify(copy[wingId][floorId])
        }
        addInfrastructure.setDataToServer.mutate(data)
    }
    const updateWing = (e, newobj, data) => {
        e.preventDefault()
        //let newObj = { ...infrastructureData }
        let Data = {
            screen_name: 'WING',
            buildingId: newobj.buildingId,
            wingData: JSON.stringify(data)
        }
        addInfrastructure.setDataToServer.mutate(Data)
    }

    const updateFloor = (e, wingId, newFloor) => {
        e.preventDefault()
        // let newObj = { ...infrastructureData }
        let Data = {
            screen_name: 'FLOOR',
            wingId: wingId,
            floorData: JSON.stringify(newFloor)
        }
        addInfrastructure.setDataToServer.mutate(Data)
    }

    return (<Dialog fullScreen fullWidth={true} open={open} onClose={handleClearAndCloseForm} TransitionComponent={Transition}>
        <div className='container_xxl'>
            <div className='flex items-center space-x-4 sticky headingBorder__b top-0 z-40 bg-white'>
                <div>
                    <IconButton onClick={handleClearAndCloseForm}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div>
                    <h1 className='heading capitalize text-2xl'>Update Infrastructure</h1>
                </div>
                <div className=''>
                    {updateBuildingMaster.setDataToServer.isLoading ? <Spinner /> : ""}
                    {updateFloorMaster.setDataToServer.isLoading ? <Spinner /> : ""}
                    {updateWingMaster.setDataToServer.isLoading ? <Spinner /> : ""}
                    {updateRoomMaster.setDataToServer.isLoading ? <Spinner /> : ""}
                    {addInfrastructure.setDataToServer.isLoading ? <Spinner /> : ""}
                    {getSingleData.setDataToServer.isLoading ? <Spinner /> : ""}
                </div>
            </div>
            <form>
                <span className='flex justify-center mt-3 text-xl font-semibold text-[#0084b5]'>Building Name</span>
                <div className='flex justify-center mt-3 space-x-5'>
                    {checkIfEmpty(infrastructureData) ? "" : <input required autocomplete="off" className='w-96 h-12 border-2 border-grey px-2 rounded-md'
                        value={infrastructureData?.buildingName}
                        name='buildingName'
                        placeholder='Building Name'
                        onChange={(e) => handleInputs(e)} />}
                </div>

                <div className="mt-5">
                    <span className='flex justify-center mt-3 text-xl font-semibold text-[#0084b5] mb-3'>Wings</span>
                    <div className="space-y-4 w-11/12 mx-auto">
                        {checkIfNotEmpty(infrastructureData) && infrastructureData?.wing?.map((data, windId) => {
                            const { wingId } = data
                            return (<div key={windId}>
                                <Accordion expanded={expanded === data?.wingId} onChange={handleChange(data?.wingId)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header">
                                        <Typography>{data?.wingName}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <form>
                                            <div className="flex space-x-14 flex-1 justify-center mb-9">
                                                <p className='mt-4 text-[#0084b5]'>Wing-Name</p>
                                                <input
                                                    required
                                                    className="input w-[48%] h-12"
                                                    type="text"
                                                    autocomplete="off"
                                                    placeholder="Wing Name"
                                                    value={data.wingName}
                                                    onChange={(e) => handleWingName(e, windId)} />
                                                <input required
                                                    className="input w-24 h-12"
                                                    type="tel"
                                                    placeholder="e.g 9"
                                                    autocomplete="off"
                                                    onChange={(e) => handleFloorInput(e, windId)}
                                                    value={data.levelCount}
                                                />
                                                <button
                                                    onClick={(e) => generateFloorField(e, windId, wingId)}
                                                    className="cursor-pointer bg-[#0084b5] text-white font-normal rounded mb-4 text-5xl lg:text-base lg:w-20 lg:mt-2 lg:rounded-full text-center py-1"
                                                ><AddCircleIcon /></button>
                                                <div className="removeWing-wrapper cursor-pointer mt-2.5" onClick={(e) => handleWingDelete(e, wingId)}>
                                                    <DeleteIcon className="removeWing-icon" />
                                                </div>
                                            </div>
                                        </form>
                                        <div>
                                            {data?.floor?.map((floorData, floorIdx) => {
                                                const { floorId } = floorData
                                                return <div key={floorIdx}>
                                                    <hr className='mt-12' />
                                                    <div className="flex items-center justify-center space-x-4 space-y-5 mt-5">
                                                        <p className='mt-5 text-[#0084b5]'>Floor-Name</p>
                                                        <input required
                                                            className='input3 mt-5'
                                                            type="text"
                                                            label="Floors"
                                                            autocomplete="off"
                                                            name={floorData}
                                                            value={floorData?.floorName}
                                                            id="floorname"
                                                            placeholder={`Floor ${floorIdx + 1}`}
                                                            onChange={(e) => handleFloorName(e, windId, floorIdx, floorData)} />


                                                        <button onClick={(e) => addRooms(e, floorId, '', '', wingId)} type="button"
                                                            className="bg-[#0084b5] text-white font-normal rounded mb-4 text-5xl lg:text-base lg:w-20 lg:mt-2 lg:rounded-full text-center py-1"><AddCircleIcon /></button>
                                                        <div className="removeFloor-wrapper cursor-pointer" onClick={(e) => handleFloorDelete(e, floorId)}>
                                                            <DeleteIcon className="" />
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-2 mt-9 space-x-2'>
                                                        {floorData?.rooms?.map((roomData, roomId) => {
                                                            // const { roomId } = roomData
                                                            return <div className='flex'>
                                                                <RoomUpdate
                                                                    roomData={roomData}
                                                                    roomId={roomId}
                                                                    windId={wingId}
                                                                    floorId={floorIdx}
                                                                    setInfrastructureData={setInfrastructureData}
                                                                    infrastructureData={infrastructureData}
                                                                    handleRoomDelete={handleRoomDelete}
                                                                    updateRooms={updateRooms}
                                                                    apiFloorId={floorId}
                                                                    handleRoomUpdate={handleRoomUpdate}
                                                                />
                                                            </div>
                                                        })}
                                                        {/* {newRooms[wingId] && newRooms[wingId][floorId]?.map((roomData, roomIndex) => {
                                                            return <div className='flex'>
                                                                <RoomUpdate
                                                                    isAdd={true}
                                                                    changeAddRoomDetails={(e) => changeAddRoomDetails(e, roomIndex, wingId, floorId)}
                                                                    roomData={roomData}
                                                                    roomId={roomIndex}
                                                                    windId={windId}
                                                                    floorId={floorIdx}
                                                                    setInfrastructureData={setInfrastructureData}
                                                                    infrastructureData={infrastructureData}
                                                                    handleRoomDelete={(e) => handleNewRoomDelete(floorId, roomIndex, wingId)}
                                                                    updateRooms={updateRooms}
                                                                />
                                                            </div>
                                                        })} */}
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>);
                        })}
                    </div>
                    <div className="flex justify-center">
                        {checkIfEmpty(infrastructureData) ? "" : <button
                            type="button"
                            className="bg-[#0084b5] rounded text-base text-white py-2 px-4 lg:p-2 lg:m-5"
                            onClick={(e) => handleAddWingForm(e)}>
                            <AddCircleIcon />
                            <span className="p-3">Add Wing</span>
                        </button>}
                    </div>
                </div>
            </form>
        </div >
        {openDeleteModal && <DeleteItem onDelete={handleRecallData} open={openDeleteModal} multiParam={deteteParams} onClose={() => setOpenDeleteModal(false)} url={"/deleteInfrastructureData"} />}
    </Dialog >

    );

}

export default UpdateInfrastructure;



//  <div key={roomId} className="w-full h-44 border-solid border-2 border-gray-500 mt-4 text-black text">
//     <div className='flex justify-center mt-9 mx-auto space-x-4'>
//         <input required className='w-60 h-12 border-2 border-grey px-2 rounded-md'
//             name='roomName'
//             placeholder='Room Name'
//             value={roomData?.roomName}
//             onChange={(e) => handleRoomInputs(e, roomId, floorId, windId)}>
//         </input>
//         <div>
//             <select onChange={(e) => handleRoomInputs(e, roomId, floorId, windId)} name="roomType" value={roomData?.roomType}
//                 className="border-2 border-grey w-60 h-12 rounded-md">
//                 <option>--Select Type--</option>
//                 {handleGetData?.data?.data?.map((roomDrop, id) => { return <option value={roomDrop?.id}>{roomDrop?.type_name}</option> })}
//             </select>
//         </div>
//     </div>
//     <div className='space-x-4 mt-5 ml-48'>
//         <button
//             onClick={() => handleRoomDelete(windId, floorId, roomId)}
//             type='button'
//             className="w-28 text-white bg-[color:var(--primaryRed)] h-9 rounded-full">Delete Room</button>
//         <button
//             onClick={() => handleRoomUpdate(floorId, roomId)}
//             type='button'
//             className="w-28 text-white bg-[color:var(--primaryBlue)] h-9 rounded-full">Update Room</button>
//     </div>
// </div>
//  <RoomUpdate
//                                                                     roomData={roomData}
//                                                                     roomId={roomId}
//                                                                     windId={windId}
//                                                                     floorId={floorId}
//                                                                     setInfrastructureData={setInfrastructureData}
//                                                                     infrastructureData={infrastructureData}
//                                                                     handleRoomDelete={handleRoomDelete}
//                                                                 />