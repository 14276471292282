import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../components/layout';
import Spinner from '../../components/spinner';
import Errors from "../errors";
import useGetDataFromServer from "../../hooks/useGetDataFromServer";
import Pagination from "../../components/pagination";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { checkIfEmpty, getDateFromTimestamp } from '../../utility';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteItem from '../../components/DeleteItem/DeleteItem';
import AddUser from './AddUser';
import EditUser from './EditUser';
import AlertContext from '../../context/alerts';
import deBounce from '../../hooks/useDebounce';

function UserMaster() {
    const initialData = {
        pageNo: 0,
        limit: 10,
        searchQuery: '',
    }
    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
    const [singleData, setSingleData] = useState({})
    const handleOpenCreateCustomer = () => {
        setIsCreateDialogOpen(true)
    }

    const [open, setOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)


    const { sendAlert } = useContext(AlertContext)


    const [isDeleted, setIsDeleted] = useState(false)
    const handleDeletedSuccess = (data) => {
        setIsDeleted(true)
        setOpen(true)
        sendAlert(data?.message, "SUCCESS")
    }
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setCreateOpen(false)
        setEditOpen(false)
    }

    const handleCreatedSuccess = () => {
        setCreateOpen(true)
    }

    const handleEditSuccess = () => {
        setEditOpen(true)
    }

    // const [openProductModal, setProductModal] = useState(false)
    // const handleProductModal = () => {
    //     setProductModal(true)
    // }





    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer("getUserList", initialData)



    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const handleCategoryDelete = (id) => {
        setSingleData(handleGetData.data.data.find(x => x.user_id === id))
        setOpenDeleteModal(true)
    }

    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false)
        setIsOpenEditDialog(false)
    }
    const handleOpenEditModal = (id) => {
        setSingleData(handleGetData.data.data.find(x => x.user_id === id))
        setIsOpenEditDialog(true)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }

    // let timer;
    // const deBounce = (data) => {
    //     clearTimeout(timer);
    //     timer = setTimeout(() => {
    //         setGetListParams(data)
    //     },500)
    // }

    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            [e.target.name]: value
        }
        deBounce(setGetListParams, data)
        // setGetListParams(data)
    }


    const onDeleteError = (data) => {
        sendAlert(data?.response?.data?.message, "ERROR")
    }

    return (<Layout>
        <div className="py-4 border-t border-gray-300">
            <div>
                <h1 className='text-center heading capitalize text-3xl'>Users</h1>
            </div>
            <div className="flex items-center space-x-4">
                <div className="flex-1">
                    <input type="text" autocomplete="off" className="input2" name="searchQuery" placeholder="Search..." value={paramsObject.search_query} onChange={handleSearch}></input>
                </div>
                <button className="btn3 text-white" onClick={handleOpenCreateCustomer}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    <span className="text-sm">Add User</span>
                </button>
            </div>
        </div>
        {handleGetData.isLoading ? <Spinner />
            : handleGetData.isError ? <Errors errorObject={handleGetData?.error} inline />
                : handleGetData?.data?.data <= 0 ? <div className="flex items-center justify-center h-96">
                    <p className="text-lg font-semibold">No Data Available</p>
                </div> : <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead>
                            <tr className="text-left">
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Sr.no
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Name
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Email
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Contact On
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {handleGetData?.data?.data?.map((vendorData, idx) => {
                                const { first_name, created_on, updated_on, username, user_id, disabled_on, active, phone_number, email, city, state } = vendorData
                                return <tr key={idx}>
                                    <td className="border-dashed border-t border-gray-200 userId">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                            {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{first_name}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{email}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{phone_number}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                                            <span data-id='1'><EditIcon onClick={() => handleOpenEditModal(user_id)} /></span>
                                            <DeleteIcon onClick={() => handleCategoryDelete(user_id)} />
                                        </span>
                                    </td>
                                </tr>
                            })}

                        </tbody>
                    </table>
                </div>
                    <div className=" my-7">
                        <Pagination currentPage={paramsObject.pageNo} pageCount={handleGetData?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick} />
                    </div>
                    {/* <Snackbar open={open} onClose={handleClose} autoHideDuration={5000}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Deleted Successfully
                        </Alert>
                    </Snackbar> */}
                    {/*<Snackbar open={createOpen} onClose={handleClose} autoHideDuration={5000}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Created Successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar open={editOpen} onClose={handleClose} autoHideDuration={5000}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Edited Successfully
                        </Alert>
                    </Snackbar> */}
                </>
        }
        <AddUser open={isCreatDialogOpen} onClose={handleCloseDialog} onCreate={handleCreatedSuccess} />
        {isOpenEditDialog && <EditUser onEdit={handleEditSuccess} singleData={singleData} open={isOpenEditDialog} onClose={() => setIsOpenEditDialog(false)} />}
        {openDeleteModal && <DeleteItem onDelete={handleDeletedSuccess} onError={onDeleteError} open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} id={singleData?.user_id} url={"/deleteUser"} queryKey={"getUserList"} />}


    </Layout>);
}
export default UserMaster;