import React, { useState, useEffect } from 'react'
import Layout from '../../components/layout';
import Spinner from '../../components/spinner';
import Errors from "../errors";
import useGetDataFromServer from "../../hooks/useGetDataFromServer";
import Pagination from "../../components/pagination";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddRoomType from "../../components/AddRoomType/AddRoomType"
import { checkIfEmpty, getDateFromTimestamp } from '../../utility';
import DeleteItem from '../../components/DeleteItem/DeleteItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import WingsSection from './WingsSection';
import CreateStats from './CreateStats';
import deBounce from '../../hooks/useDebounce';
// import EditPurchaseProduct from './EditPurchaseProduct';


function StatsMaster() {
    const initialData = {
        pageNo: 0,
        limit: 10,
        searchQuery: '',
    }
    const [createOpen, setCreateOpen] = useState(false)
    const [section, setSection] = useState(1);
    const [editOpen, setEditOpen] = useState(false)
    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
    const [open, setOpen] = useState(false);

    const [isDeleted, setIsDeleted] = useState(false)
    const handleDeletedSuccess = () => {
        setIsDeleted(true)
        setOpen(true)
    }

    const handleCreatedSuccess = () => {
        setCreateOpen(true)
    }

    const handleEditSuccess = () => {
        setEditOpen(true)
    }

    const [singleData, setSingleData] = useState({})
    const [buildingId, setBuildingId] = useState()
    const handleOpenCreateCustomer = () => {
        setIsCreateDialogOpen(true)
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer("getBuildingList", initialData)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [message, setMessage] = useState('')
    const handleRoomDelete = (id) => {
        setSingleData(handleGetData.data.data)
        setOpenDeleteModal(true)
    }

    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false)
        setIsOpenEditDialog(false)
    }

    const handleOpenEditModal = (id) => {
        setSingleData(handleGetData.data.data.find(x => x.id === id))
        setIsOpenEditDialog(true)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setCreateOpen(false)
        setEditOpen(false)
    }

    const goToSection = (sectionNo, buildingId) => {
        setSection(sectionNo);
        setBuildingId(buildingId)
    }
    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            [e.target.name]: value
        }
        deBounce(setGetListParams, data)
        // setGetListParams(data)
    }
    return (<Layout>
        {section === 1 && <>
            <div className="py-4 border-t border-gray-300">
            <div>
                <h1 className='text-center heading capitalize text-3xl'>Stats</h1>
            </div>
                <div className="flex items-center space-x-4">
                    <div className="flex-1">
                        <input type="text" autocomplete="off" className="input2" name="searchQuery" placeholder="Search by building name..." value={paramsObject.search_query} onChange={handleSearch}></input>
                    </div>
                </div>
            </div>
            {handleGetData.isLoading ? <Spinner />
                : handleGetData.isError ? <Errors errorObject={handleGetData?.error} inline />
                    : <> <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                                <tr className="text-left">
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Sr.no
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Building Name
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Total Wings
                                    </th>
                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {handleGetData?.data.data.map((roomData, idx) => {
                                    const { buildingName, vendor_name, total_wings, business_email, buildingId, disabled_on, active, roomName } = roomData
                                    return <tr key={idx}>
                                        <td className="border-dashed border-t border-gray-200 userId">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                            </span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{buildingName}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{total_wings}</span>
                                        </td>
                                        <td className="border-dashed border-t border-gray-200 firstName">
                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                                                <span data-id='1'><EditIcon onClick={() => goToSection(2, buildingId)} /></span>
                                                {/* <DeleteIcon onClick={() => handleRoomDelete(buildingId)} /> */}
                                            </span>
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                        <div className="my-7">
                            <Pagination currentPage={paramsObject.pageNo} pageCount={handleGetData?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick} />
                        </div>
                    </>
            }
            <CreateStats open={isCreatDialogOpen} onClose={handleCloseDialog} onCreate={handleCreatedSuccess} />

        </>}
        {section === 2 && <WingsSection data={handleGetData.data.data} buildingId={buildingId} goBack={() => goToSection(1)} />}
    </Layout >);
}

export default StatsMaster;