import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../components/layout';
import Spinner from '../../components/spinner';
import Errors from "../errors";
import useGetDataFromServer from "../../hooks/useGetDataFromServer";
import Pagination from "../../components/pagination";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddInfrastructure from '../../components/AddInfrastructure/AddInfrastructure';
import { checkIfEmpty, getDateFromTimestamp } from '../../utility';
import UpdateInfrastructure from './UpdateInfrastructure';
import DeleteInfrastructure from './DeleteInfrastructure';
import DeleteItem from '../../components/DeleteItem/DeleteItem';
import AlertContext from '../../context/alerts';
import deBounce from '../../hooks/useDebounce';

function Infrastructure() {
    const initialData = {
        pageNo: 0,
        limit: 10,
        searchQuery: '',
    }
    const { sendAlert } = useContext(AlertContext)

    const [isCreatDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
    const [singleData, setSingleData] = useState()
    const [currentEditId, setCurrentEditId] = useState()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deteteParams, setDeleteParams] = useState({})


    const handleOpenCreateCustomer = () => {
        setIsCreateDialogOpen(true)
    }
    const handleCloseDialog = () => {
        setIsCreateDialogOpen(false)
        setIsOpenEditDialog(false)
    }
    const { handleGetData, paramsObject, setGetListParams } = useGetDataFromServer("getBuildingList", initialData)
    const handleOpenEditModal = (id) => {
        setSingleData(handleGetData.data.data.find(x => x.id === id))
        setCurrentEditId(id)
        setIsOpenEditDialog(true)
    }
    const handleInfraDelete = (id) => {
        setSingleData(handleGetData.data.data.find(x => x.id === id))
        setOpenDeleteModal(true)
        setCurrentEditId(id)
        let data = {
            screen_name: 'BUILDING',
            id: id
        }
        setDeleteParams(data)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }
    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            [e.target.name]: value
        }
        deBounce(setGetListParams, data)
        // setGetListParams(data)
    }
    const onDeleteSuccess = (data) => {
        sendAlert(data?.message, "SUCCESS")
    }

    const onDeleteError = (data) => {
        sendAlert(data?.response?.data?.message, "ERROR")
    }
    return (<Layout>
        <div className="py-4 border-t border-gray-300">
        <div>
                <h1 className='text-center heading capitalize text-3xl'>Infrastructure</h1>
            </div>
            <div className="flex items-center space-x-4">
                <div className="flex-1">
                    <input type="text" autocomplete="off" className="input2" name="searchQuery" placeholder="Search..." value={paramsObject.search_query} onChange={handleSearch}></input>
                </div>
                <button className="btn3 text-white" onClick={handleOpenCreateCustomer}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    <span className="text-sm">Add Infrastructure</span>
                </button>
            </div>
        </div>
        {handleGetData.isLoading ? <Spinner />
            : handleGetData.isError ? <Errors errorObject={handleGetData?.error} inline />
                : handleGetData?.data?.data <= 0 ? <div className="flex items-center justify-center h-96">
                    <p className="text-lg font-semibold">No Data Available</p>
                </div> : <><div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead>
                            <tr className="text-left">
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Sr.no
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Name
                                </th>
                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {handleGetData?.data?.data?.map((InfraData, idx) => {
                                const { buildingName, created_on, disabled_on, updated_on, buildingId, active } = InfraData
                                return <tr key={idx}>
                                    <td className="border-dashed border-t border-gray-200 userId">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                            {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{buildingName}</span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm space-x-4 cursor-pointer">
                                            <span data-id='1'><EditIcon onClick={() => handleOpenEditModal(buildingId)} /></span>
                                            <DeleteIcon onClick={() => handleInfraDelete(buildingId)} />
                                        </span>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                    <div className="my-7">
                        <Pagination currentPage={paramsObject.pageNo} pageCount={handleGetData?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick} />
                    </div>
                </>
        }
        {isCreatDialogOpen && <AddInfrastructure open={isCreatDialogOpen} onClose={handleCloseDialog} buildingId={currentEditId} singleData={singleData} />}
        {isOpenEditDialog && <UpdateInfrastructure open={isOpenEditDialog} onClose={handleCloseDialog} buildingId={currentEditId} singleData={singleData} />}
        {openDeleteModal && <DeleteItem onDelete={onDeleteSuccess} onError={onDeleteError} open={openDeleteModal} multiParam={deteteParams} onClose={() => setOpenDeleteModal(false)} url={"/deleteInfrastructureData"} queryKey={"getBuildingList"} />}

    </Layout>);
}

export default Infrastructure;